import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useI18n } from '@braincube/i18n';

import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import { me as getMe } from 'services/api/sso';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionContent, MainSectionTitle } from 'components/MainSection';

import NewSite from './components/NewSite';
import SiteChooser from './components/SiteChooser';
import updateProduct from './services/api/sso';

function Site() {
    const i18n = useI18n();
    const { dispatch } = useContext(SsoContext);
    const [me, setMe] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        dispatch(startFetching());

        getMe()
            .then((response) => {
                dispatch(stopFetching());

                if (response.ok) {
                    response.json().then(setMe);
                } else {
                    navigate('/');
                }
            })
            .catch(() => {
                dispatch(stopFetching());

                navigate('/');
            });
    }, [dispatch, navigate]);

    const product = useMemo(() => {
        try {
            return me.accessList.find((access) => access.product.productId === location.state?.productId).product;
        } catch (e) {
            return null;
        }
    }, [me, location]);

    const handleNextStep = useCallback(() => {
        updateProduct({
            ...product,
            providerName: selectedProduct.providerName,
            site: selectedProduct.site,
            siteName: selectedProduct.site.siteName,
            clientName: selectedProduct.site.groupName,
        })
            .then(() => window.location.assign(location.state.redirect))
            .catch(() => navigate('/'));
    }, [product, selectedProduct, navigate, location]);

    const canCreateSite = useMemo(() => {
        if (me) {
            return (
                location.state?.isNewUser ||
                (me.accessList.length === 1 && me.accessList[0].product.siteName === 'default')
            );
        }

        return false;
    }, [location, me]);

    return me ? (
        <MainSection>
            <MainSectionTitle>{i18n.t('onboarding.site.title')}</MainSectionTitle>
            <MainSectionContent>
                {canCreateSite ? (
                    <NewSite selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
                ) : (
                    <SiteChooser me={me} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
                )}
                <ButtonsGroupWithHelp
                    disableBigButton={!product || !selectedProduct?.site.siteName || !selectedProduct?.site.groupName}
                    onBigButtonClick={handleNextStep}
                    label={i18n.tc('app.nextButton')}
                />
            </MainSectionContent>
        </MainSection>
    ) : null;
}

export default Site;
