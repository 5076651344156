import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Autocomplete, Stack, styled, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { me as getMe, checkOnboardingCode, onboardBraincubeTransfer } from 'services/api/sso';

import { MainSection, MainSectionContent, MainSectionTitle } from '../../components/MainSection';
import Explanation from '../../components/Explanation';
import Input from '../../components/Input';
import ButtonsGroupWithHelp from '../../components/ButtonsGroupWithHelp';
import Loader from './components/Loader';
import ForceOnboardDialog from './components/ForceOnboardDialog';

const StyledAlert = styled(Alert)({
    textAlign: 'left',
});

function getOptionLabel(option) {
    return option.product.name;
}

function BraincubeTransferOnboarding() {
    const i18n = useI18n();

    const [checkCodeErrorMessage, setCheckCodeErrorMessage] = useState('');
    const [onboardingErrorMessage, setOnboardingErrorMessage] = useState('');

    const [checkingCode, setCheckingCode] = useState(false);
    const [code, setCode] = useState('');

    const [isManagedUser, setIsManagedUser] = useState(false);

    const [tampixList, setTampixList] = useState([]);

    const [name, setName] = useState('');
    const [selectedTampix, setSelectedTampix] = useState(null);

    const [onboardingProcess, setOnboardingProcess] = useState(false);
    const [successfullyOnboarded, setSuccessfullyOnboarded] = useState(false);

    const [allowForceOnboarding, setAllowForceOnboarding] = useState(false);

    const checkForm = useCallback(
        (event, force) => {
            setOnboardingProcess(true);

            onboardBraincubeTransfer(name, selectedTampix.product.productId, code, force)
                .then(() => {
                    setSuccessfullyOnboarded(true);
                    setOnboardingErrorMessage('');
                })
                .catch((e) => {
                    if (e.status === 404) {
                        setOnboardingErrorMessage(i18n.tc('onboarding.braincubeTransfer.onboarding.errors.code'));
                    } else if (e.status === 409) {
                        setAllowForceOnboarding(true);
                    } else {
                        setOnboardingErrorMessage(i18n.tc('onboarding.braincubeTransfer.onboarding.errors.general'));
                    }
                })
                .finally(() => setOnboardingProcess(false));
        },
        [code, i18n, name, selectedTampix?.product?.productId]
    );

    /**
     * Check the url code validity
     * If the code is not valid, display an error message (and the onboarding form is not visible)
     */
    const checkCode = useCallback(
        (codeFromUrl) => {
            setCheckingCode(true);

            checkOnboardingCode(codeFromUrl)
                .then(() => {
                    setCheckCodeErrorMessage('');
                })
                .catch((e) => {
                    // Invalid code
                    if (e.status === 404 || e.status === 400) {
                        setCheckCodeErrorMessage(i18n.tc('onboarding.braincubeTransfer.query.errors.invalidCode'));
                    } else {
                        // Error
                        setCheckCodeErrorMessage(i18n.tc('onboarding.braincubeTransfer.query.errors.general'));
                    }
                })
                .finally(() => setCheckingCode(false));
        },
        [i18n]
    );

    /**
     * Get the connected user information
     * If it's a managed user, the onboarding is forbidden
     * Else we get his tampix products, and we check the identification code form the url to start the onboarding
     */
    const getUserAndHisTampix = useCallback(() => {
        getMe().then((response) => {
            if (response.ok) {
                response.json().then(({ user, accessList }) => {
                    if (user.owner) {
                        setIsManagedUser(true);
                    } else {
                        // Get only tampix product of the user (user access should be IPLWADMIN or ADMIN)
                        setTampixList(
                            accessList.filter(
                                (access) =>
                                    access.product.type === 'tampix' &&
                                    ['IPLWADMIN', 'ADMIN'].includes(access.accessRight)
                            )
                        );

                        // Go to check the code from url
                        const parameters = new URLSearchParams(window.location.search);
                        const codeFromUrl = parameters.get('code');

                        if (codeFromUrl) {
                            setCode(codeFromUrl);
                            checkCode(codeFromUrl);
                        }
                    }
                });
            }
        });
    }, [checkCode]);

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                error={!selectedTampix}
                helperText={!selectedTampix ? i18n.tc('onboarding.braincubeTransfer.name.error') : null}
                label={i18n.tc('onboarding.braincubeTransfer.selectProduct')}
            />
        ),
        [i18n, selectedTampix]
    );

    const handleChange = useCallback((event, selectedOption) => setSelectedTampix(selectedOption), []);

    const cancelForceOnboarding = useCallback(() => {
        setAllowForceOnboarding(false);
    }, []);

    const forceOnboarding = useCallback(
        (e) => {
            setAllowForceOnboarding(false);

            checkForm(e, true);
        },
        [checkForm]
    );

    useEffect(() => {
        getUserAndHisTampix();
    }, [getUserAndHisTampix]);

    return (
        <MainSection onEnter={checkForm}>
            <MainSectionTitle>{i18n.t('onboarding.braincubeTransfer.title')}</MainSectionTitle>
            <MainSectionContent>
                {isManagedUser && (
                    <StyledAlert severity="error">{i18n.tc('onboarding.braincubeTransfer.isManagedUser')}</StyledAlert>
                )}

                {!code && !isManagedUser ? (
                    <StyledAlert severity="error">{i18n.tc('onboarding.braincubeTransfer.missingCode')}</StyledAlert>
                ) : (
                    <>
                        {checkingCode && <Loader message={i18n.tc('onboarding.braincubeTransfer.checkingCode')} />}
                        {checkCodeErrorMessage && <StyledAlert severity="error">{checkCodeErrorMessage}</StyledAlert>}

                        {onboardingProcess && (
                            <Loader message={i18n.tc('onboarding.braincubeTransfer.onboarding.progress')} />
                        )}
                        {onboardingErrorMessage && <StyledAlert severity="error">{onboardingErrorMessage}</StyledAlert>}

                        {successfullyOnboarded && (
                            <StyledAlert severity="success">
                                {i18n.tc('onboarding.braincubeTransfer.query.success.onboarded')}
                            </StyledAlert>
                        )}

                        {!isManagedUser &&
                            !checkingCode &&
                            !checkCodeErrorMessage &&
                            !onboardingProcess &&
                            !successfullyOnboarded && (
                                <>
                                    <Explanation>
                                        <Stack direction="column" spacing={2}>
                                            <Input
                                                label={i18n.tc('onboarding.braincubeTransfer.name.label')}
                                                name="name"
                                                value={name}
                                                type="text"
                                                placeholder={i18n.tc('onboarding.braincubeTransfer.name.placeholder')}
                                                onChange={setName}
                                                error={!name}
                                                textError={
                                                    !name ? i18n.tc('onboarding.braincubeTransfer.name.error') : null
                                                }
                                                autoFocus
                                                data-testid="onboarding-bt-name"
                                            />
                                            <Autocomplete
                                                renderInput={renderInput}
                                                getOptionLabel={getOptionLabel}
                                                value={selectedTampix}
                                                onChange={handleChange}
                                                options={tampixList}
                                                data-testid="onboarding-bt-tampix"
                                            />
                                        </Stack>
                                    </Explanation>
                                    <ButtonsGroupWithHelp
                                        disableBigButton={!name || !selectedTampix}
                                        label={i18n.tc('app.nextButton')}
                                        onBigButtonClick={checkForm}
                                    />
                                </>
                            )}

                        <ForceOnboardDialog
                            onCancel={cancelForceOnboarding}
                            onConfirm={forceOnboarding}
                            opened={allowForceOnboarding}
                            name={name}
                        />
                    </>
                )}
            </MainSectionContent>
        </MainSection>
    );
}

export default BraincubeTransferOnboarding;
