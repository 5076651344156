import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useI18n } from '@braincube/i18n';

import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function Error() {
    const i18n = useI18n();
    const navigate = useNavigate();

    const handleClick = useCallback(() => navigate('/onboarding/link'), [navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.tc('onboarding.error.title')}</MainSectionTitle>
            <MainSectionContent>
                {i18n.tc('onboarding.error.indication')}
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('app.backButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Error;
