import { LocalesMapper } from '@braincube/i18n';

import en from './en.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import pl from './pl.json';
import ptBr from './pt_br.json';
import ru from './ru.json';
import tr from './tr.json';

export default new LocalesMapper({
    [LocalesMapper.LOCALES.EN]: en,
    [LocalesMapper.LOCALES.DE]: de,
    [LocalesMapper.LOCALES.ES]: es,
    [LocalesMapper.LOCALES.FR]: fr,
    [LocalesMapper.LOCALES.IT]: it,
    [LocalesMapper.LOCALES.NL]: nl,
    [LocalesMapper.LOCALES.PL]: pl,
    [LocalesMapper.LOCALES.PT_BR]: ptBr,
    [LocalesMapper.LOCALES.RU]: ru,
    [LocalesMapper.LOCALES.TR]: tr,
});
