import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { SsoContext } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function InvalidTicket() {
    const i18n = useI18n();
    const { state } = useContext(SsoContext);
    const navigate = useNavigate();

    const handleClick = useCallback(() => navigate(state.email !== '' ? '/recovery' : '/'), [navigate, state.email]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('password.ticket.title')}</MainSectionTitle>
            <MainSectionContent>
                <div>
                    <Typography variant="body2">{i18n.tc('password.ticket.invalid')}</Typography>
                </div>
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('password.ticket.retryButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default InvalidTicket;
