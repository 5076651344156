import React from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { MainSection, MainSectionContent, MainSectionTitle } from '../../components/MainSection';
import { InstallStepper } from './components';

const StyledMainSection = styled(MainSection)({
    width: 'auto',
    maxWidth: 800,
});

const ALLOWED_BROWSERS = ['firefox', 'chrome', 'edge'];
const ALLOWED_OS = ['macos', 'windows'];

function HowToInstall() {
    const i18n = useI18n();
    const navigate = useNavigate();

    const parameters = new URLSearchParams(window.location.search);
    const browser = parameters.get('browser');
    const os = parameters.get('os');

    if (!browser || !os || !ALLOWED_BROWSERS.includes(browser) || !ALLOWED_OS.includes(os)) {
        navigate('/certificate');
    }

    return (
        <StyledMainSection>
            <MainSectionTitle>
                {i18n.tc('certificate.ticket.configurationProcessFor')} {browser} - {os}
            </MainSectionTitle>
            <MainSectionContent>
                <InstallStepper browser={browser} os={os} />
            </MainSectionContent>
        </StyledMainSection>
    );
}

export default HowToInstall;
