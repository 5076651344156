import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Fab, Tooltip, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { HelpQuestion } from '@braincube/svg';

import LangSwitcher from '../LangSwitcher';

const StyledContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    '& > div': {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
}));

const StyledHelpIcon = styled(HelpQuestion)({
    width: 18,
    height: 18,
});

const StyledCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
});

const popperProps = {
    disablePortal: true,
};

function ButtonsGroupWithHelp({ onBigButtonClick, label, disableBigButton }) {
    const i18n = useI18n();
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate('/help');
    }, [navigate]);

    return (
        <StyledContainer>
            <LangSwitcher />
            <StyledCenter>
                <StyledTooltip
                    title={disableBigButton ? i18n.tc('password.disableTooltip') : null}
                    PopperProps={popperProps}
                >
                    <div>
                        <Fab
                            disabled={disableBigButton}
                            type="submit"
                            variant="extended"
                            color="primary"
                            size="medium"
                            onClick={onBigButtonClick}
                        >
                            {label}
                        </Fab>
                    </div>
                </StyledTooltip>
            </StyledCenter>
            <StyledTooltip title={i18n.tc('app.helpTooltip')} PopperProps={popperProps}>
                <Fab onClick={handleClick} size="small" color="secondary" aria-label="Help">
                    <StyledHelpIcon fill="#FFF" />
                </Fab>
            </StyledTooltip>
        </StyledContainer>
    );
}

ButtonsGroupWithHelp.propTypes = {
    onBigButtonClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disableBigButton: PropTypes.bool,
};

ButtonsGroupWithHelp.defaultProps = {
    disableBigButton: false,
};

export default ButtonsGroupWithHelp;
