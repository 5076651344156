import React from 'react';

import { useI18n } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function Request() {
    const i18n = useI18n();

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('app.emailSent')}</MainSectionTitle>
            <MainSectionContent>
                <Explanation>{i18n.tc('onboarding.request')}</Explanation>
            </MainSectionContent>
        </MainSection>
    );
}

export default Request;
