import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, TextField } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { accessPropType } from '../SiteChooser';

function NewSite({ selectedProduct, setSelectedProduct }) {
    const i18n = useI18n();

    const handleGroupNameChange = useCallback(
        (e) => {
            setSelectedProduct({
                ...selectedProduct,
                providerName: 'braincube.eu',
                site: {
                    ...selectedProduct?.site,
                    groupName: e.target.value,
                    clientName: e.target.value,
                    provider: 'braincube.eu',
                },
            });
        },
        [selectedProduct, setSelectedProduct]
    );

    const handleSiteNameChange = useCallback(
        (e) => {
            setSelectedProduct({
                ...selectedProduct,
                providerName: 'braincube.eu',
                site: { ...selectedProduct?.site, siteName: e.target.value, provider: 'braincube.eu' },
            });
        },
        [selectedProduct, setSelectedProduct]
    );

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <TextField
                    fullWidth
                    autoFocus
                    value={selectedProduct?.site?.groupName || ''}
                    label={i18n.tc('onboarding.site.groupName')}
                    onChange={handleGroupNameChange}
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    value={selectedProduct?.site?.siteName || ''}
                    label={i18n.tc('onboarding.site.siteName')}
                    onChange={handleSiteNameChange}
                />
            </Grid>
        </Grid>
    );
}

NewSite.propTypes = {
    selectedProduct: accessPropType,
    setSelectedProduct: PropTypes.func.isRequired,
};

NewSite.defaultProps = {
    selectedProduct: null,
};

export default NewSite;
