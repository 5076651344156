/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import Cookies from 'js-cookie';
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Typography, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { startFetching, stopFetching, setEmail, SsoContext } from 'App/contexts';
import { lookup, PREFERRED_CONNECTION_MODE_COOKIE_NAME } from 'services/api/sso';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent, MainSectionFooter } from 'components/MainSection';
import Input from 'components/Input';
import { handleCertLogin } from 'scenes/Certify';

const StyledButton = styled('button')({
    border: 'none',
    background: 'none',
});

function Lookup() {
    const [isEmailValid, setEmailValid] = useState(true);
    const [responseError, setResponseError] = useState(null);
    const { state, dispatch } = useContext(SsoContext);
    const i18n = useI18n();
    const navigate = useNavigate();
    const location = useLocation();

    const lookupSucceed = useCallback(
        (response) => {
            if (response.status === 200) {
                response.json().then((json) => {
                    if (json.type === 'REDIRECT') {
                        window.location.assign(`${window.location.origin}${json.message}`);
                    } else {
                        dispatch(stopFetching());
                        navigate({ pathname: '/challenge', search: location.search });
                    }
                });
            } else if (response.status === 204) {
                dispatch(stopFetching());

                setEmailValid(false);
                setResponseError('lookup.accountDoesNotExist');
            }
        },
        [dispatch, navigate, location.search]
    );

    const lookupFailed = useCallback(
        (response) => {
            dispatch(stopFetching());

            try {
                response.json().then(({ type }) => {
                    if (type === 'ACCOUNT_BLOCKED') {
                        setEmailValid(false);
                        setResponseError('lookup.accountBlocked');
                    }
                });
            } catch (e) {
                setEmailValid(false);
                setResponseError('lookup.error');
            }
        },
        [dispatch]
    );

    const processLookup = useCallback(() => {
        dispatch(startFetching());

        lookup(state.email, location.search).then(lookupSucceed).catch(lookupFailed);
    }, [dispatch, location.search, lookupFailed, lookupSucceed, state.email]);

    const checkEmailValidity = useCallback(() => {
        setResponseError(null);

        if (
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                state.email
            )
        ) {
            setEmailValid(true);
            processLookup();
        } else {
            setEmailValid(false);
        }
    }, [processLookup, state.email]);

    const handleEmailChange = useCallback(
        (email) => {
            dispatch(setEmail(email));
        },
        [dispatch]
    );

    const onCertLogin = useCallback(() => {
        handleCertLogin(dispatch, navigate, location);
    }, [dispatch, navigate, location]);

    const forceToShowGdpr = useCallback(() => {
        navigate('/terms-and-conditions');
    }, [navigate]);

    useEffect(() => {
        const cookie = Cookies.get(PREFERRED_CONNECTION_MODE_COOKIE_NAME);

        if (cookie === 'certificate') {
            onCertLogin();
        }
    }, [onCertLogin]);

    return (
        <MainSection onEnter={checkEmailValidity}>
            <MainSectionTitle>{i18n.t('lookup.title')}</MainSectionTitle>
            <MainSectionContent>
                <Input
                    name="mail"
                    placeholder={i18n.tc('app.emailPlaceholder')}
                    type="email"
                    value={state.email}
                    onChange={handleEmailChange}
                    error={!isEmailValid || state.isEmailRequiredForNextStep}
                    textError={responseError ? i18n.tc(responseError) : null}
                    autoFocus
                />
                <ButtonsGroupWithHelp onBigButtonClick={checkEmailValidity} label={i18n.tc('app.nextButton')} />
            </MainSectionContent>
            <MainSectionFooter>
                <StyledButton type="button" onClick={onCertLogin}>
                    <Typography variant="overline">{i18n.t('lookup.certificate')}</Typography>
                </StyledButton>

                <StyledButton type="button" onClick={forceToShowGdpr} sx={{ pt: 0 }}>
                    <Typography variant="overline">{i18n.t('lookup.showGdpr')}</Typography>
                </StyledButton>
            </MainSectionFooter>
        </MainSection>
    );
}

export default Lookup;
