import React from 'react';
import PropTypes from 'prop-types';

import { Fab, styled } from '@mui/material';

import LangSwitcher from '../LangSwitcher';

const StyledContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    '& > div': {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
}));

const StyledLang = styled('div')({
    position: 'absolute',
    bottom: -20,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

function ButtonsGroup({ onBigButtonClick, label }) {
    return (
        <>
            <StyledContainer>
                <div>
                    <Fab variant="extended" color="primary" size="medium" onClick={onBigButtonClick}>
                        {label}
                    </Fab>
                </div>
            </StyledContainer>
            <StyledLang>
                <LangSwitcher />
            </StyledLang>
        </>
    );
}

ButtonsGroup.propTypes = {
    onBigButtonClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default ButtonsGroup;
