import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Logger from '@braincube/logger';

import { SsoContextProvider } from 'App/contexts';
import App from 'App';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import { getCdnOrigin } from 'services/api/sso';

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

function Init() {
    useEffect(() => {
        Logger.configure({
            sentry: process.env.NODE_ENV === 'production',
            appName: 'sso-front',
            version: process.env.REACT_APP_VERSION,
            sentryProjectId: 7,
            sentryToken: 'fa39ef4d549741d388ba938acba66e91',
        });
    }, []);

    return (
        <GlobalErrorBoundary>
            <SsoContextProvider>
                <App />
            </SsoContextProvider>
        </GlobalErrorBoundary>
    );
}

script.async = false;
script.type = 'text/javascript';
script.src = `${getCdnOrigin()}/configuration.js?timestamp=${Date.now()}`;
script.onload = function render() {
    ReactDOM.render(<Init />, document.getElementById('root'));
};

head.appendChild(script);
