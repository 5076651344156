import React from 'react';

import { styled } from '@mui/material';

const StyledContent = styled('div')(({ theme }) => ({
    width: '100%',
    borderBottom: `1px dotted ${theme.palette.common.white}`,
    borderTop: `1px dotted ${theme.palette.common.white}`,
    padding: theme.spacing(2, 0),
}));

function Content({ children }) {
    return <StyledContent>{children}</StyledContent>;
}

export default Content;
