import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography, styled, Link } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import ButtonsGroup from 'components/ButtonsGroup';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

const StyledHelp = styled('div')(({ theme }) => ({
    textAlign: 'left',
    fontSize: 10,
    color: theme.palette.common.white,
}));

const StyledTitleCap = styled('div')(({ theme }) => ({
    textTransform: 'uppercase',
    borderTop: `1px dotted ${theme.palette.common.white}`,
    paddingTop: theme.spacing(1),
    '&:first-of-type': {
        borderTop: 'none',
        paddingTop: 0,
    },
}));

const StyledList = styled('ul')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    '&:last-of-type': {
        marginBottom: 0,
    },
    '& a': {
        textDecoration: 'none',
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    '&:empty': {
        display: 'none',
    },
}));

const StyledHelpContent = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(1),
    '& a': {
        textDecoration: 'underline',
    },
}));

const StyledNumList = styled('ol')(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    '& > li': {
        marginBottom: theme.spacing(1),
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
}));

const StyledLink = styled(Link)({
    textDecoration: 'underline !important',
});

const StyledUl = styled('ul')({
    paddingLeft: 25,
});

function Help() {
    const i18n = useI18n();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('help.title')}</MainSectionTitle>
            <MainSectionContent>
                <StyledHelp>
                    {location.hash === '' && (
                        <StyledTitleCap>
                            <Typography variant="body2">{i18n.t('help.connexionProblem.title')}</Typography>
                        </StyledTitleCap>
                    )}

                    <StyledList>
                        {(location.hash === '' || location.hash.includes('forgot-password')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#forgot-password">
                                        {i18n.tc('help.connexionProblem.forgotPassword.title')}
                                    </a>
                                    {location.hash.includes('forgot-password') && (
                                        <StyledHelpContent id="forgot-password">
                                            {i18n.tc(
                                                'help.connexionProblem.forgotPassword.content.solutionTitleFirstPart'
                                            )}
                                            <Link to="/recovery">
                                                {i18n.t(
                                                    'help.connexionProblem.forgotPassword.content.solutionTitleLink'
                                                )}
                                            </Link>
                                            {i18n.tc(
                                                'help.connexionProblem.forgotPassword.content.solutionTitleSecondPart'
                                            )}
                                            <StyledNumList>
                                                <li>
                                                    {i18n.tc('help.connexionProblem.forgotPassword.content.one')}
                                                    <a href="mailto:support@braincube.com">
                                                        {i18n.t('help.connexionProblem.forgotPassword.content.oneLink')}
                                                    </a>
                                                </li>
                                                <li>
                                                    {i18n.tc('help.connexionProblem.forgotPassword.content.two')}
                                                    <a href="#secure-password">
                                                        {i18n.tc(
                                                            'help.connexionProblem.forgotPassword.content.twoLink'
                                                        )}
                                                    </a>
                                                </li>
                                            </StyledNumList>
                                        </StyledHelpContent>
                                    )}
                                </Typography>
                            </li>
                        )}

                        {(location.hash === '' || location.hash.includes('connection-problem')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#connection-problem">{i18n.tc('help.connexionProblem.problem.title')}</a>
                                    {location.hash.includes('connection-problem') && (
                                        <StyledHelpContent id="connection-problem">
                                            {i18n.tc('help.connexionProblem.problem.content')}
                                        </StyledHelpContent>
                                    )}
                                </Typography>
                            </li>
                        )}

                        {(location.hash === '' || location.hash.includes('invalid-certificate')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#invalid-certificate">
                                        {i18n.tc('help.connexionProblem.invalidCertificate.title')}
                                    </a>
                                    {location.hash.includes('invalid-certificate') && (
                                        <>
                                            <StyledHelpContent id="invalid-certificate">
                                                {i18n.tc('help.connexionProblem.invalidCertificate.content.one')}
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://cdn.mybraincube.com/certificat/html/certificat.html#enregistrer"
                                                    target="_blank"
                                                >
                                                    {i18n.t('help.connexionProblem.invalidCertificate.content.oneLink')}
                                                </a>
                                            </StyledHelpContent>
                                            <StyledHelpContent id="invalid-certificate">
                                                {i18n.tc('help.connexionProblem.invalidCertificate.content.two')}
                                            </StyledHelpContent>
                                            <StyledHelpContent id="invalid-certificate">
                                                {i18n.tc(
                                                    'help.connexionProblem.invalidCertificate.content.threeFirstPart'
                                                )}
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://cdn.mybraincube.com/certificat/html/certificat.html#genererCertificat"
                                                    target="_blank"
                                                >
                                                    {i18n.t(
                                                        'help.connexionProblem.invalidCertificate.content.threeLink'
                                                    )}
                                                </a>
                                                {i18n.tc(
                                                    'help.connexionProblem.invalidCertificate.content.threeSecondPart'
                                                )}
                                            </StyledHelpContent>
                                        </>
                                    )}
                                </Typography>
                            </li>
                        )}
                    </StyledList>

                    {location.hash === '' && (
                        <StyledTitleCap>
                            <Typography variant="body2">{i18n.t('help.accountCommands.title')}</Typography>
                        </StyledTitleCap>
                    )}

                    <StyledList>
                        {(location.hash === '' || location.hash.includes('account-change-mail-name')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#account-change-mail-name">
                                        {i18n.tc('help.accountCommands.updateEmail.title')}
                                    </a>
                                    {location.hash.includes('account-change-mail-name') && (
                                        <StyledHelpContent id="account-change-mail-name">
                                            {i18n.tc('help.accountCommands.updateEmail.content')}
                                        </StyledHelpContent>
                                    )}
                                </Typography>
                            </li>
                        )}

                        {(location.hash === '' || location.hash.includes('account-change-password')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#account-change-password">
                                        {i18n.tc('help.accountCommands.resetPassword.title')}
                                    </a>
                                    {location.hash.includes('account-change-password') && (
                                        <StyledHelpContent id="account-change-password">
                                            {i18n.tc('help.accountCommands.resetPassword.content')}
                                        </StyledHelpContent>
                                    )}
                                </Typography>
                            </li>
                        )}

                        {(location.hash === '' || location.hash.includes('account-change-lang')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#account-change-lang">
                                        {i18n.tc('help.accountCommands.switchLang.title')}
                                    </a>
                                    {location.hash.includes('account-change-lang') && (
                                        <>
                                            <StyledHelpContent id="account-change-lang">
                                                {i18n.tc('help.accountCommands.switchLang.content.one')}
                                            </StyledHelpContent>
                                            <StyledHelpContent id="account-change-lang">
                                                {i18n.tc('help.accountCommands.switchLang.content.two')}
                                            </StyledHelpContent>
                                            <StyledHelpContent id="account-change-lang">
                                                {i18n.tc('help.accountCommands.switchLang.content.three')}
                                            </StyledHelpContent>
                                        </>
                                    )}
                                </Typography>
                            </li>
                        )}
                    </StyledList>

                    {location.hash === '' && (
                        <StyledTitleCap>
                            <Typography variant="body2">{i18n.t('help.security.title')}</Typography>
                        </StyledTitleCap>
                    )}

                    <StyledList>
                        {(location.hash === '' || location.hash.includes('secure-password')) && (
                            <>
                                <li>
                                    <Typography variant="caption">
                                        <a href="#secure-password">{i18n.tc('help.security.password.title')}</a>
                                        {location.hash.includes('secure-password') && (
                                            <>
                                                <StyledHelpContent id="secure-password">
                                                    {i18n.tc('help.security.password.content.title')}
                                                </StyledHelpContent>
                                                <StyledHelpContent id="secure-password">
                                                    {i18n.tc('help.security.password.content.one')}
                                                </StyledHelpContent>
                                                <StyledHelpContent id="secure-password">
                                                    {i18n.tc('help.security.password.content.two')}
                                                </StyledHelpContent>
                                                <StyledHelpContent id="secure-password">
                                                    {i18n.tc('help.security.password.content.three')}
                                                </StyledHelpContent>
                                                <StyledHelpContent id="secure-password">
                                                    <div>{i18n.tc('help.security.password.content.four')}</div>
                                                    <div>
                                                        {'- '}
                                                        <a href="#forgot-password">
                                                            {i18n.tc('help.security.password.content.fourOne')}
                                                        </a>
                                                    </div>
                                                    <div>
                                                        {'- '}
                                                        <a href="#connection-problem">
                                                            {i18n.tc('help.security.password.content.fourTwo')}
                                                        </a>
                                                    </div>
                                                </StyledHelpContent>
                                            </>
                                        )}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="caption">{i18n.tc('help.certificate.connexion')}</Typography>
                                    <StyledUl>
                                        <li>
                                            <Typography variant="caption">
                                                {i18n.t('certificate.ticket.onMacOs')}{' '}
                                                <StyledLink href="/certificate/how-to-install?browser=chrome&os=macos">
                                                    {i18n.tc('certificate.ticket.chrome')}
                                                </StyledLink>
                                                {' / '}
                                                <StyledLink href="/certificate/how-to-install?browser=firefox&os=macos">
                                                    {i18n.tc('certificate.ticket.firefox')}
                                                </StyledLink>
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="caption" paragraph>
                                                {i18n.t('certificate.ticket.onWindows')}{' '}
                                                <StyledLink href="/certificate/how-to-install?browser=chrome&os=windows">
                                                    {i18n.tc('certificate.ticket.chrome')}
                                                </StyledLink>
                                                {' / '}
                                                <StyledLink href="/certificate/how-to-install?browser=firefox&os=windows">
                                                    {i18n.tc('certificate.ticket.firefox')}
                                                </StyledLink>
                                                {' / '}
                                                <StyledLink href="/certificate/how-to-install?browser=edge&os=windows">
                                                    {i18n.tc('certificate.ticket.edge')}
                                                </StyledLink>
                                            </Typography>
                                        </li>
                                    </StyledUl>
                                </li>
                            </>
                        )}
                    </StyledList>

                    {location.hash === '' && (
                        <StyledTitleCap>
                            <Typography variant="body2">{i18n.t('help.miscellaneous.title')}</Typography>
                        </StyledTitleCap>
                    )}

                    <StyledList>
                        {(location.hash === '' || location.hash.includes('recommended-browsers')) && (
                            <li>
                                <Typography variant="caption">
                                    <a href="#recommended-browsers">{i18n.tc('help.miscellaneous.browsers.title')}</a>
                                    {location.hash.includes('recommended-browsers') && (
                                        <StyledHelpContent id="secure-password">
                                            {i18n.tc('help.miscellaneous.browsers.content')}
                                        </StyledHelpContent>
                                    )}
                                </Typography>
                            </li>
                        )}
                    </StyledList>
                    <ButtonsGroup label={i18n.tc('app.backButton')} onBigButtonClick={handleClick} />
                </StyledHelp>
            </MainSectionContent>
        </MainSection>
    );
}

export default Help;
