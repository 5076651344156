import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material';

import { LogoBraincubeColor } from '@braincube/svg';

import { SsoContext } from 'App/contexts';

import Title from './Title';
import Content from './Content';
import Footer from './Footer';

const containerStyles = (theme, paddingBottom) => ({
    width: 300,
    padding: `${theme.spacing(4)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'relative',
    margin: '0 auto',
    paddingBottom: paddingBottom ? theme.spacing(4) : 0,
});

const StyledContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'paddingBottom' })(
    ({ theme, paddingBottom }) => ({
        ...containerStyles(theme, paddingBottom),
    })
);

const StyledForm = styled('form', { shouldForwardProp: (prop) => prop !== 'paddingBottom' })(
    ({ theme, paddingBottom }) => ({
        ...containerStyles(theme, paddingBottom),
    })
);

const StyledLogoLink = styled('a')(({ theme }) => ({
    position: 'absolute',
    width: 100,
    height: 100,
    top: -70,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    color: theme.palette.common.black,
    '& > svg': {
        width: 100,
        height: 100,
    },
}));

function MainSection({ children, onEnter, ...other }) {
    const { state } = useContext(SsoContext);

    const child = (
        <>
            <StyledLogoLink
                href={
                    state.brandingTheme && state.brandingTheme.logo && state.brandingTheme.logo.link
                        ? state.brandingTheme.logo.link
                        : 'https://braincube.com'
                }
                target="_blank"
                rel="noopener noreferrer"
            >
                {state.brandingTheme && state.brandingTheme.logo && state.brandingTheme.logo.url ? (
                    <img src={state.brandingTheme.logo.url} width={100} height={100} alt="logo" />
                ) : (
                    <LogoBraincubeColor />
                )}
            </StyledLogoLink>
            {children}
        </>
    );

    const handleKeyUp = useCallback(
        ({ target, code }) => {
            if (code === 13) {
                target.form.querySelector('button[type="submit"]').click();
                onEnter();
            }
        },
        [onEnter]
    );

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
    }, []);

    if (onEnter) {
        return (
            <StyledForm method="POST" paddingBottom={children.length < 3} onKeyUp={handleKeyUp} onSubmit={handleSubmit}>
                {child}
            </StyledForm>
        );
    }

    return (
        <StyledContainer paddingBottom={children.length < 3} {...other}>
            {child}
        </StyledContainer>
    );
}

MainSection.propTypes = {
    onEnter: PropTypes.func,
};

MainSection.defaultProps = {
    onEnter: null,
};

export { MainSection, Title as MainSectionTitle, Content as MainSectionContent, Footer as MainSectionFooter };
