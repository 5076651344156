import Cookies from 'js-cookie';
import React, { useReducer, createContext, useMemo } from 'react';
import { validate as isValidUuid } from 'uuid';

import { fetchWithBearer } from '@braincube/jwt-browser';

import SsoLocalStorage from 'persistent/localStorage';
import { getState, getBrandingTheme } from 'services/api/sso';

// export const availableLangs = ['de', 'en', 'en-us', 'es', 'fr', 'it', 'nl', 'pl', 'pt-br', 'ru'];
export const availableLangs = ['en', 'en-us', 'fr'];

const SsoContext = createContext();

const initialState = {
    brandingTheme: null,
    email: SsoLocalStorage.getEmail() || '',
    lang: availableLangs.find((lang) => lang === SsoLocalStorage.getLang()) || 'en',
    isFetching: false,
    isEmailRequiredForNextStep: false,
};

const SET_BRANDING_THEME = 'set-branding-theme';
const SET_EMAIL = 'set-email';
const START_FETCHING = 'start-fetching';
const STOP_FETCHING = 'stop-fetching';
const SET_LANG = 'set-lang';
const SET_EMAIL_IS_REQUIRED_FOR_NEXT_STEP = 'email-is-required-for-next-step';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_BRANDING_THEME:
            return { ...state, brandingTheme: action.payload };
        case SET_EMAIL:
            return { ...state, email: action.payload };
        case START_FETCHING:
            return { ...state, isFetching: true };
        case STOP_FETCHING:
            return { ...state, isFetching: false };
        case SET_LANG:
            return { ...state, lang: action.payload };
        case SET_EMAIL_IS_REQUIRED_FOR_NEXT_STEP:
            return { ...state, isEmailRequiredForNextStep: true };
        default:
            return state;
    }
};

function SsoContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({ state, dispatch }), [state]);

    return <SsoContext.Provider value={value}>{children}</SsoContext.Provider>;
}

function setBrandingTheme(brandingTheme) {
    return {
        type: SET_BRANDING_THEME,
        payload: brandingTheme,
    };
}

function startFetching() {
    return {
        type: START_FETCHING,
    };
}

function stopFetching() {
    return {
        type: STOP_FETCHING,
    };
}

function setEmail(email) {
    SsoLocalStorage.setEmail(email);
    return {
        type: SET_EMAIL,
        payload: email,
    };
}

function setLang(lang) {
    SsoLocalStorage.setLang(lang);

    return {
        type: SET_LANG,
        payload: lang,
    };
}

function emailIsRequiredForNextStep() {
    return {
        type: SET_EMAIL_IS_REQUIRED_FOR_NEXT_STEP,
    };
}

function fetchBrandingTheme(dispatch) {
    function noBranding() {
        dispatch(setBrandingTheme(false));
    }

    getBrandingTheme()
        .then((response) => {
            if (response.ok) {
                return Promise.resolve(response);
            }

            return Promise.reject();
        })
        .then((response) => {
            try {
                return response.json();
            } catch (e) {
                return Promise.reject();
            }
        })
        .then((json) => {
            dispatch(setBrandingTheme(json));
        })
        .catch(() => {
            noBranding();
        });
}

function mapAppToAccessList(app, ssoRedirectUrl) {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.sso}/sso-server/ws/user/me`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject();
        })
        .then((me) => {
            try {
                const accessFound = me.accessList.find((access) => access.product.url === ssoRedirectUrl);

                if (!accessFound) {
                    return Promise.reject();
                }

                const appUrl = decodeURI(app.url || app.latestVersion.appUrl)
                    .replace(/{CDN}\//g, `{CDN}`)
                    .replace(/{CDN}/g, `https://${window.BC_API_ENDPOINTS_CONF.cdn}/`)
                    .replace(/{SERVER_NAME}/g, `${accessFound.product.name}.${window.BC_API_ENDPOINTS_CONF.base}`)
                    .replace(/{PRODUCT_NAME}/g, accessFound.product.name)
                    .replace(/{PRODUCT_UUID}/g, accessFound.product.productId)
                    .replace(/{SITE_UUID}/g, accessFound.product.site.siteUuid);

                return Promise.resolve(appUrl);
            } catch (e) {
                return Promise.reject();
            }
        });
}

function isValidHttpUrl(string) {
    return !!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(
        string
    );
}

function getPlatformApps() {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/platformApps`).then((response) => {
        if (response.ok) {
            return response.json();
        }

        return Promise.reject();
    });
}

function findFavoriteApplication(favoriteApplication, ssoRedirectUrl) {
    if (isValidUuid(favoriteApplication)) {
        return getPlatformApps().then((platformApps) => {
            const foundPlatformApps = platformApps.find((platformApp) => platformApp.id === favoriteApplication);

            if (foundPlatformApps) {
                return mapAppToAccessList(foundPlatformApps, ssoRedirectUrl);
            }

            return fetchWithBearer(
                `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${favoriteApplication}`
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }

                    return Promise.reject();
                })
                .then((app) => {
                    return mapAppToAccessList(app, ssoRedirectUrl);
                });
        });
    }

    if (isValidHttpUrl(favoriteApplication)) {
        return Promise.resolve(favoriteApplication);
    }

    return Promise.reject();
}

function checkFavoriteApplication(ssoRedirectUrl) {
    return fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.preference}/users/general/favoriteApplication`)
        .then((favoriteApplicationResponse) => {
            if (favoriteApplicationResponse.status === 200) {
                return favoriteApplicationResponse.json();
            }

            return Promise.reject();
        })
        .then((preferences) => {
            const favoriteApplication = preferences?.settings?.general?.favoriteApplication;

            if (favoriteApplication) {
                return Promise.resolve(favoriteApplication);
            }

            return Promise.reject();
        })
        .then((favoriteApplication) => {
            return findFavoriteApplication(favoriteApplication, ssoRedirectUrl);
        })
        .then((favoriteApplicationUrl) => {
            window.location.assign(favoriteApplicationUrl);
        })
        .catch(() => {
            fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.preference}/global/marioIsDead`)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }

                    return Promise.reject();
                })
                .then((json) => {
                    try {
                        if (json.settings.marioIsDead === true) {
                            return Promise.resolve();
                        }

                        throw new Error();
                    } catch (e) {
                        return Promise.reject();
                    }
                })
                .then(() => {
                    window.location.assign(`https://${window.BC_API_ENDPOINTS_CONF.cdn}/dashboards`);
                })
                .catch(() => {
                    window.location.assign(ssoRedirectUrl);
                });
        });
}

function checkState(dispatch) {
    if (window.location.pathname === '/') {
        dispatch(startFetching());

        getState()
            // eslint-disable-next-line consistent-return
            .then((response) => {
                if (response.status === 200) {
                    const ssoCheckCookie = Cookies.get('sso-check');

                    if (ssoCheckCookie) {
                        Cookies.remove('sso-check');
                        window.location.assign('/access-list');
                    } else {
                        const inTwentySeconds = new Date(new Date().getTime() + 20 * 1000);

                        Cookies.set('sso-check', 1, {
                            expires: inTwentySeconds,
                        });

                        return response.text();
                    }
                } else {
                    return Promise.reject();
                }
            })
            // eslint-disable-next-line consistent-return
            .then((url) => {
                const parameters = new URLSearchParams(window.location.search);
                const redirectParameter = parameters.get('redirect');

                if (redirectParameter !== null) {
                    window.location.assign(redirectParameter);
                } else {
                    return checkFavoriteApplication(url);
                }
            })
            .catch(() => {
                dispatch(stopFetching());
            });
    }
}

export {
    SsoContext,
    SsoContextProvider,
    setBrandingTheme,
    startFetching,
    stopFetching,
    setEmail,
    setLang,
    emailIsRequiredForNextStep,
    fetchBrandingTheme,
    checkState,
    checkFavoriteApplication,
};
