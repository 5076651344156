import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

function ForceOnboardDialog({ opened, onCancel, onConfirm, name }) {
    const i18n = useI18n();

    return (
        <Dialog open={opened}>
            <DialogTitle>{i18n.tc('onboarding.braincubeTransfer.forceOnboarding.title')}</DialogTitle>
            <DialogContent>
                <Alert severity="error">
                    {i18n.tc('onboarding.braincubeTransfer.forceOnboarding.alert', { name })}
                </Alert>
                <Typography mt={2} variant="body2" paragraph>
                    {i18n.tc('onboarding.braincubeTransfer.forceOnboarding.description')}
                </Typography>
                <Typography variant="body2">
                    {i18n.tc('onboarding.braincubeTransfer.forceOnboarding.areYouSure', { name })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{i18n.tc('app.cancel')}</Button>
                <Fab variant="extended" size="medium" color="primary" onClick={onConfirm}>
                    {i18n.tc('app.confirm')}
                </Fab>
            </DialogActions>
        </Dialog>
    );
}

ForceOnboardDialog.propTypes = {
    opened: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default ForceOnboardDialog;
