import React, { useCallback, useMemo, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import Input from 'components/Input';

function handleMouseDownPassword(event) {
    event.preventDefault();
}

function InputPassword({ ...props }) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    const inputProps = useMemo(
        () => ({
            endAdornment: (
                // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
                <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            ),
        }),
        [handleClickShowPassword, showPassword]
    );

    return <Input {...props} type={showPassword ? 'text' : 'password'} inputProps={inputProps} />;
}

export default InputPassword;
