import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Typography, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import InputPassword from 'components/InputPassword';
import { SsoContext, startFetching, stopFetching, setEmail } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import EmailButton from 'components/EmailButton';
import { getPasswordRules, resetPassword, validateRgpd } from 'services/api/sso';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import Gdpr from 'scenes/Account/Gdpr';
import UserAgreement from 'scenes/Onboarding/UserAgreement';

const StyledIconWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
});

const StyledPasswordContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledRuleList = styled('ul')(({ theme }) => ({
    textAlign: 'left',
    paddingLeft: 0,
    marginLeft: 0,
    fontSize: 12,
    color: theme.palette.common.white,
    listStyleType: 'none',
    marginTop: theme.spacing(1),
    marginBottom: 0,
    '& > li': {
        display: 'flex',
        marginTop: 5,
        height: 18,
        lineHeight: '18px',
    },
}));

const GdprComponent = <Gdpr />;
const areRulesValid = [];

function Password() {
    const { state, dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [rules, setRules] = useState([]);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [lastProductUrl, setLastProductUrl] = useState('');
    const [showUserAgreement, setShowUserAgreement] = useState(false);

    const [newPasswordDidntMatch, setNewPasswordDidntMatch] = useState(false);
    const i18n = useI18n();

    const isPasswordInitialization = location.pathname === '/creation';

    useEffect(() => {
        dispatch(startFetching());

        const parameters = new URLSearchParams(window.location.search);

        getPasswordRules(parameters.get('passwordTicket'))
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then((response) => {
                dispatch(stopFetching());

                const filteredRules = response.rules.filter((rule) => {
                    return !(
                        rule.includes('BAN_OLD_PASSWORD;') ||
                        rule.includes('BAN_WORDS_PASSWORD;') ||
                        rule.includes('DELAY_CHANGE_PASSWORD;') ||
                        rule.includes('BAN_COMMON_PASSWORD') ||
                        rule.includes('BAN_KEYBOARD_SEQ') ||
                        rule.includes('BAN_CHAR_SEQ')
                    );
                });

                dispatch(setEmail(response.email));
                setRules(filteredRules);
            })
            .catch(() => {
                dispatch(stopFetching());
                navigate('/password/invalid-ticket');
            });
    }, [dispatch, navigate]);

    const successRedirect = useCallback(
        (message) => {
            if (message === null) {
                navigate('/no-access');
            } else {
                window.location.assign(message);
            }
        },
        [navigate]
    );

    const resetSuccess = useCallback(
        ({ type, message, payload }) => {
            dispatch(stopFetching());
            if (isPasswordInitialization) {
                if (type === 'CHANGE_2FA') {
                    navigate('/2fa/change', {
                        state: {
                            step: 'CHANGE_2FA',
                            seed: JSON.parse(payload),
                            isNewUser: true,
                        },
                    });
                } else {
                    // forward onboarding: true to use later
                    navigate('/account/create', {
                        state: { redirect: message, onboarding: type === 'PASS_ONBOARDING' },
                    });
                }
            } else if (type === 'PASS_RGPD') {
                setLastProductUrl(message);
                setShowUserAgreement(true);
                dispatch(stopFetching());
            } else if (type === 'PASS') {
                successRedirect(message);
            } else if (type === 'PASS_ONBOARDING') {
                navigate('/onboarding/link');
            } else if (type === 'OTP_NEEDED') {
                navigate({ pathname: '/challenge/2fa', search: location.search });
            }
        },
        [dispatch, navigate, isPasswordInitialization, location.search, successRedirect]
    );

    const resetFailed = useCallback(
        (response) => {
            dispatch(stopFetching());
            const response2 = response.clone(); // je clone la response pour pouvoir y accéder en cas d'erreur

            if (response.status === 401) {
                response
                    .json()
                    .then(({ message, value }) => {
                        setPasswordError({
                            rule: message,
                            count: value,
                        });
                    })
                    .catch(() => {
                        response2.text().then((rule) => {
                            const splits = rule.split(':');
                            const value = splits[1].split('=')[1].substring(0, 1);
                            setPasswordError({
                                rule: splits[0],
                                count: value,
                            });
                        });
                    });
            }
        },
        [dispatch]
    );

    const checkRule = useCallback(
        (rule) => {
            if (rule.includes('NB_UPPERCASE;')) {
                return new RegExp(`([A-Z].*){${rule.split(';')[1]},}`, 'gm').test(password);
            }

            if (rule.includes('NB_LOWERCASE;')) {
                return new RegExp(`([a-z].*){${rule.split(';')[1]},}`, 'gm').test(password);
            }

            if (rule.includes('LENGTH;')) {
                const splits = rule.split(';');

                return password.length >= splits[1] && password.length <= splits[2];
            }

            if (rule.includes('NB_NUMBER;')) {
                return new RegExp(`([0-9].*){${rule.split(';')[1]},}`, 'gm').test(password);
            }

            if (rule.includes('NB_SPECIAL')) {
                return new RegExp(`([^A-Za-z0-9].*){${rule.split(';')[1]}}`, 'gm').test(password);
            }

            return false;
        },
        [password]
    );

    const processReset = useCallback(() => {
        setFormSubmit(true);
        setPasswordError(null);
        setNewPasswordDidntMatch(false);

        if (password !== newPassword) {
            setNewPasswordDidntMatch(true);
        } else if (
            password &&
            password !== '' &&
            newPassword &&
            newPassword !== '' &&
            rules.filter(checkRule).length === rules.length
        ) {
            dispatch(startFetching());

            const parameters = new URLSearchParams(window.location.search);

            resetPassword(newPassword, parameters.get('passwordTicket'))
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then(resetSuccess)
                .catch(resetFailed);
        }
    }, [checkRule, dispatch, newPassword, password, resetFailed, resetSuccess, rules]);

    const labels = useMemo(
        () => ({
            title: i18n.tc('onboarding.userAgreement.rgpd.title'),
            indication: i18n.tc('onboarding.userAgreement.rgpd.indication'),
            checkBoxLabel: i18n.tc('onboarding.userAgreement.checkbox'),
            postpone: i18n.tc('onboarding.userAgreement.postpone'),
        }),
        [i18n]
    );

    const handleUserAgreementAccept = useCallback(() => {
        validateRgpd().then(() => successRedirect(lastProductUrl));
        setShowUserAgreement(false);
    }, [lastProductUrl, successRedirect]);

    const handleUserAgreementPostpone = useCallback(() => {
        successRedirect(lastProductUrl);
        setShowUserAgreement(false);
    }, [lastProductUrl, successRedirect]);

    const resetEmail = useCallback(() => {
        dispatch(setEmail(''));
        navigate('/');
    }, [dispatch, navigate]);

    return showUserAgreement ? (
        <UserAgreement
            labels={labels}
            content={GdprComponent}
            onUserAgreementAccept={handleUserAgreementAccept}
            onUserAgreementPostpone={handleUserAgreementPostpone}
        />
    ) : (
        <MainSection onEnter={processReset}>
            <MainSectionTitle>{i18n.t('password.title')}</MainSectionTitle>
            <MainSectionContent>
                {state.email !== '' && <EmailButton onClick={resetEmail} email={state.email} />}
                <StyledPasswordContainer>
                    <InputPassword
                        name="password"
                        onChange={setPassword}
                        value={password}
                        placeholder={i18n.tc('app.passwordPlaceholder')}
                        error={(formSubmit && password === '') || passwordError}
                        textError={
                            passwordError
                                ? i18n.tc(`password.rules.${passwordError.rule}`, {
                                      num: passwordError.count,
                                      smart_count: passwordError.count,
                                  })
                                : null
                        }
                        autoFocus
                    />
                </StyledPasswordContainer>
                <StyledPasswordContainer>
                    <InputPassword
                        name="newPassword"
                        onChange={setNewPassword}
                        value={newPassword}
                        placeholder={i18n.tc('password.placeholder')}
                        error={(formSubmit && newPassword === '') || newPasswordDidntMatch}
                        textError={newPasswordDidntMatch ? i18n.tc('password.rules.DIDNT_MATCH') : null}
                    />
                </StyledPasswordContainer>
                <StyledRuleList>
                    {rules.map((rule, idx) => {
                        const isValidRule = checkRule(rule);
                        const splits = rule.split(';');

                        areRulesValid[idx] = isValidRule;

                        return (
                            <li key={rule}>
                                <StyledIconWrapper>
                                    {isValidRule && <DoneIcon htmlColor="#FFF" />}
                                    {!isValidRule && <ClearIcon htmlColor="red" />}
                                </StyledIconWrapper>
                                <Typography variant="caption">
                                    {i18n.tc(`password.rules.${splits[0]}`, {
                                        min: splits[1],
                                        max: splits[2],
                                        num: splits[1],
                                        smart_count: Number(splits[1]),
                                    })}
                                </Typography>
                            </li>
                        );
                    })}
                </StyledRuleList>
                <ButtonsGroupWithHelp
                    label={i18n.tc('password.button')}
                    onBigButtonClick={processReset}
                    disableBigButton={areRulesValid.some((valid) => !valid)}
                />
            </MainSectionContent>
        </MainSection>
    );
}

export default Password;
