import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { MainSection, MainSectionContent, MainSectionTitle } from 'components/MainSection';
import { confirmNewMail } from 'scenes/EmailChange/services/api';

function EmailChange() {
    const i18n = useI18n();
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const finishCode = useMemo(() => searchParams.get('finishCode'), [searchParams]);

    useEffect(() => {
        if (!finishCode) {
            navigate('/');
        } else {
            confirmNewMail(finishCode)
                .then(() => setSuccess(true))
                .catch(() => navigate('/'));
        }
    }, [finishCode, navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.tc('email.change.title')}</MainSectionTitle>
            <MainSectionContent>
                {success && <Typography>{i18n.tc('email.change.success')}</Typography>}
            </MainSectionContent>
        </MainSection>
    );
}

export default EmailChange;
