import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet/lib/Helmet';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import { CssBaseline, createTheme, ThemeProvider, styled } from '@mui/material';

import { I18nProvider, getLocaleKey } from '@braincube/i18n';

import DynamicFavicon from 'components/DynamicFavicon';
import { checkState, fetchBrandingTheme, SsoContext } from 'App/contexts';
import IEBanner from 'components/IEBanner';
import Progress from 'components/Progress';
import LabelsMapping from 'services/lang/LabelsMapping';
import SsoRouter from 'components/Router';
import defaultFavicon from 'favicon.ico';

const StyledContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'brandingBackground' })(
    ({ theme, brandingBackground }) => ({
        backgroundImage: 'radial-gradient(circle at top right, #013a58, black)',
        position: 'relative',
        border: `40px solid ${theme.palette.common.black}`,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        '&:before': {
            minHeight: 110,
            flexGrow: 1,
            content: '""',
            display: 'block',
        },
        '&:after': {
            minHeight: 40,
            flexGrow: 1,
            content: '""',
            display: 'block',
        },
        [theme.breakpoints.down('xs')]: {
            border: 'none',
        },
        [`@media (max-height: ${theme.breakpoints.values.sm}px)`]: {
            border: 'none',
        },
        ...brandingBackground,
    })
);

/**
 * This is the main component of our application, it manages the assembly and communication between our various components
 */
function App() {
    const { state, dispatch } = useContext(SsoContext);

    useEffect(() => {
        checkState(dispatch);
    }, [dispatch]);

    return (
        <>
            <IEBanner />
            <DynamicFavicon
                favicon={
                    state.brandingTheme && state.brandingTheme.favicon ? state.brandingTheme.favicon : defaultFavicon
                }
            />
            <Helmet>
                <title>
                    {state.brandingTheme && state.brandingTheme.title ? state.brandingTheme.title : 'Braincube'}
                </title>
            </Helmet>
            <CssBaseline />
            <StyledContainer brandingBackground={state?.brandingTheme?.background}>
                <Progress />
                <SsoRouter />
            </StyledContainer>
        </>
    );
}

function AppWithThemeProvider() {
    const { state, dispatch } = useContext(SsoContext);

    useEffect(() => {
        fetchBrandingTheme(dispatch);
    }, [dispatch]);

    const getTheme = () => {
        if (state.brandingTheme && state.brandingTheme.muiTheme) {
            return createTheme(state.brandingTheme.muiTheme);
        }
        return createTheme({
            palette: {
                primary: {
                    main: '#ffffff',
                    light: '#ffffff',
                    dark: '#cccccc',
                },
                secondary: {
                    main: '#013a58',
                    light: '#3b6485',
                    dark: '#00142f',
                },
                mode: 'dark',
            },
            overrides: {
                MuiPopover: {
                    paper: {
                        'box-shadow': 'none',
                    },
                },
            },
        });
    };

    if (state.brandingTheme === null) {
        return null;
    }

    const locale = getLocaleKey(state.lang);

    return (
        <ThemeProvider theme={getTheme()}>
            <I18nProvider locale={locale} labels={LabelsMapping.getLabels(locale)}>
                <BrowserRouter>
                    <CypressHistorySupport />
                    <App />
                </BrowserRouter>
            </I18nProvider>
        </ThemeProvider>
    );
}

function CypressHistorySupport() {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.Cypress) {
            window.cyNavigate = navigate;
        }
    }, [navigate]);

    return null;
}

export default AppWithThemeProvider;
