function getUrl() {
    return `https://${window.BC_API_ENDPOINTS_CONF.sso}/sso-server/ws/mail/reset`;
}

export function getEmailFromConfirmCode(confirmCode) {
    return fetch(`${getUrl()}?code=${confirmCode}`).then((response) => {
        if (response.status === 200) {
            return response.json();
        }

        return Promise.reject(response);
    });
}

export function postNewMail(confirmCode, password, newMail) {
    return fetch(
        `${getUrl()}?code=${confirmCode}&password=${encodeURIComponent(password)}&newMail=${encodeURIComponent(
            newMail
        )}`,
        { method: 'POST' }
    );
}

export function confirmNewMail(finishCode) {
    return fetch(`${getUrl()}?finishCode=${finishCode}`);
}
