import React, { useState, useContext, useCallback } from 'react';
import MuiPhoneInput from 'material-ui-phone-number';
import { useLocation, useNavigate } from 'react-router-dom';

import { TextField, Autocomplete, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import EmailButton from 'components/EmailButton';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import { modifyUser, getUser } from 'services/api/sso';

import CountrySelect from './flagsAutocomplete';

const StyledInputContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledEmptyDiv = styled('div')({
    width: 10,
});

const departments = [
    { label: 'Production / Operations' },
    { label: 'Process / Continuous Improvement' },
    { label: 'Technical / Maintenance' },
    { label: 'R&D' },
    { label: 'Data Architect' },
    { label: 'Quality' },
    { label: 'IT / Automation' },
    { label: 'Digital / Manuf 4.0' },
    { label: 'Purchasing' },
];

const roles = [{ label: 'VP' }, { label: 'Director' }, { label: 'Manager' }, { label: 'Member' }];

function getOptionLabel(option) {
    return option.label;
}

function renderOption(props, option) {
    return <li {...props}>{option.label}</li>;
}

const muiPhoneNumberSx = {
    svg: {
        height: '20px',
    },
};

function Account() {
    const { state, dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [company, setCompany] = useState('');
    const [department, setDepartment] = useState('');
    const [role, setRole] = useState('');
    const [title, setTitle] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [country, setCountry] = useState('France');

    const i18n = useI18n();

    const processModify = useCallback(() => {
        setFormSubmit(true);

        if (
            company &&
            company !== '' &&
            firstName &&
            firstName !== '' &&
            lastName &&
            lastName !== '' &&
            country &&
            country !== '' &&
            department &&
            department !== '' &&
            title &&
            title !== '' &&
            role &&
            role !== ''
        ) {
            dispatch(startFetching());

            getUser(state.email).then((result) => {
                result.json().then((user) => {
                    modifyUser(state.email, {
                        ...user,
                        firstName,
                        lastName,
                        telephoneNumber: phoneNumber,
                        companyName: company,
                        department,
                        role,
                        title,
                        country,
                    })
                        .then(() => {
                            dispatch(stopFetching());
                            navigate('/account/create/preference', {
                                state: {
                                    redirect: location.state.redirect,
                                    onboarding: location.state.onboarding,
                                },
                            });
                        })
                        .catch(() => {
                            dispatch(stopFetching());
                        });
                });
            });
        }
    }, [
        company,
        country,
        department,
        dispatch,
        firstName,
        navigate,
        location,
        lastName,
        phoneNumber,
        role,
        state.email,
        title,
    ]);

    const handleCountryChange = useCallback((event, value) => {
        setCountry(value);
    }, []);

    const handleFistNameChange = useCallback((e) => {
        setFirstName(e.target.value);
    }, []);

    const handleLastNameChange = useCallback((e) => {
        setLastName(e.target.value);
    }, []);

    const handlePhoneChange = useCallback((value) => {
        setPhoneNumber(value);
    }, []);

    const handleCompanyChange = useCallback((e) => {
        setCompany(e.target.value);
    }, []);

    const renderDepartmentField = useCallback(
        (params) => {
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            const inputProps = {
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
            };

            return (
                <TextField
                    name="departmentTextfield"
                    {...params}
                    error={formSubmit && department === ''}
                    label={i18n.tc('account.create.department')}
                    inputProps={inputProps}
                />
            );
        },
        [department, formSubmit, i18n]
    );

    const handleDepartmentChange = useCallback((e, value) => {
        setDepartment(value?.label || '');
    }, []);

    const renderRoleField = useCallback(
        (params) => {
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            const inputProps = {
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
            };

            return (
                <TextField
                    name="roleTextfield"
                    {...params}
                    error={formSubmit && role === ''}
                    label={i18n.tc('account.create.role')}
                    inputProps={inputProps}
                />
            );
        },
        [formSubmit, i18n, role]
    );

    const handleRoleChange = useCallback((event, value) => {
        setRole(value?.label || '');
    }, []);

    const handleTitleChange = useCallback((e) => {
        setTitle(e.target.value);
    }, []);

    return (
        <MainSection onEnter={processModify}>
            <MainSectionTitle>{i18n.t('account.create.title')}</MainSectionTitle>
            <MainSectionContent>
                <EmailButton email={state.email} />
                <StyledInputContainer>
                    <CountrySelect onChange={handleCountryChange} />
                </StyledInputContainer>
                <StyledInputContainer>
                    <TextField
                        name="firstName"
                        error={formSubmit && firstName === ''}
                        onChange={handleFistNameChange}
                        value={firstName}
                        autoComplete="no"
                        placeholder={i18n.tc('account.create.firstNamePlaceholder')}
                        type="text"
                        autoFocus
                    />
                    <StyledEmptyDiv />
                    <TextField
                        name="lastName"
                        error={formSubmit && lastName === ''}
                        onChange={handleLastNameChange}
                        value={lastName}
                        autoComplete="no"
                        placeholder={i18n.tc('account.create.lastNamePlaceholder')}
                        type="text"
                    />
                </StyledInputContainer>
                <StyledInputContainer>
                    <MuiPhoneInput
                        name="phoneNumber"
                        defaultCountry="fr"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        fullWidth
                        autoComplete="off"
                        label={i18n.tc('account.create.phoneNumber')}
                        variant="outlined"
                        sx={muiPhoneNumberSx}
                    />
                </StyledInputContainer>
                <StyledInputContainer>
                    <TextField
                        name="company"
                        error={formSubmit && company === ''}
                        onChange={handleCompanyChange}
                        value={company.toString()}
                        autoComplete="no"
                        placeholder={i18n.tc('account.create.companyPlaceholder')}
                        type="text"
                        fullWidth
                    />
                </StyledInputContainer>
                <StyledInputContainer>
                    <Autocomplete
                        renderInput={renderDepartmentField}
                        options={departments}
                        onChange={handleDepartmentChange}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        fullWidth
                    />
                </StyledInputContainer>
                <StyledInputContainer>
                    <Autocomplete
                        renderInput={renderRoleField}
                        options={roles}
                        onChange={handleRoleChange}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        fullWidth
                    />
                </StyledInputContainer>
                <StyledInputContainer>
                    <TextField
                        name="title"
                        error={formSubmit && title === ''}
                        onChange={handleTitleChange}
                        value={title.toString()}
                        autoComplete="no"
                        placeholder={i18n.tc('account.create.jobTitle')}
                        type="text"
                        fullWidth
                    />
                </StyledInputContainer>
                <ButtonsGroupWithHelp label={i18n.tc('account.create.button')} onBigButtonClick={processModify} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Account;
