import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography, Fab, Link as MuiLink, styled } from '@mui/material';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';

import { useI18n } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import Input from 'components/Input';
import {
    getLicence,
    getLicenceBase,
    getLicenceKeyFromLocation,
    registerLicence,
    registerOfflineLicence,
} from 'services/api/sso';

import UserAgreement from '../UserAgreement';
import pdf from './eula.pdf';

const StyledOfflineButton = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
}));

const StyledExtendedIcon = styled(InsertDriveFile)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledInputFile = styled('input')({
    display: 'none',
});

function Link() {
    const { dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const routerLocation = useLocation();

    const parameters = new URLSearchParams(window.location.search);

    const [productId, setProductId] = useState(parameters.get('productId') || '');
    const [productIdError, setProductIdError] = useState(null);
    const [boxId, setBoxId] = useState(parameters.get('boxId') || '');
    const [boxIdError, setBoxIdError] = useState(null);
    const [formSubmit, setFormSubmit] = useState(false);
    const [showUserAgreement, setShowUserAgreement] = useState(false);
    const [userAgreementAccepted, setUserAgreementAccepted] = useState(false);
    const [offlineMode, setOfflineMode] = useState(false);
    const [location, setLocation] = useState(null);
    const inputFile = useRef();

    const i18n = useI18n();

    useEffect(() => {
        dispatch(startFetching());

        getLicence()
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                if (response.status === 204) {
                    return Promise.resolve({});
                }
                return Promise.reject();
            })
            .then((json) => {
                dispatch(stopFetching());
                if (json?.keyId) {
                    setBoxId(json.keyId);
                }
                if (json?.productId) {
                    setProductId(json.productId);
                }
            })
            .catch(() => {
                dispatch(stopFetching());
                navigate('/');
            });
    }, [dispatch, navigate]);

    const registerLicenceSuccess = useCallback(
        (response) => {
            if (response.status === 200) {
                if (location) {
                    getLicenceKeyFromLocation(location, productId).then(({ license }) => {
                        const data = new Blob([JSON.stringify({ license, productUuid: productId })], {
                            type: 'text/plain',
                        });
                        const url = window.URL.createObjectURL(data);
                        const tempLink = document.createElement('a');
                        tempLink.href = url;
                        tempLink.setAttribute('download', 'licence.key');
                        tempLink.click();
                    });
                } else {
                    response.json().then(({ type, message }) => {
                        if (type === 'SITE_CHOOSER') {
                            navigate('/onboarding/site', {
                                state: {
                                    redirect: message,
                                    isNewUser: routerLocation?.state?.isNewUser,
                                    productId,
                                },
                            });
                        } else {
                            window.location.assign(message);
                        }
                    });
                }
            }
        },
        [navigate, routerLocation, location, productId]
    );

    const registerLicenceFailed = (response) => {
        if (response.status === 403) {
            response.json().then(({ type }) => {
                if (type === 'INVALID_LICENCE') {
                    setBoxIdError('Invalid Box ID');
                } else if (type === 'INVALID_PRODUCTID') {
                    setProductIdError('Invalid Product ID');
                }
            });
        }
    };

    const processLink = useCallback(
        (userAgreementConfirm = userAgreementAccepted) => {
            setFormSubmit(true);
            setProductIdError(null);
            setBoxIdError(null);

            if (boxId && boxId !== '' && productId && productId !== '') {
                if (userAgreementConfirm) {
                    registerLicence(productId, boxId).then(registerLicenceSuccess).catch(registerLicenceFailed);
                } else {
                    setShowUserAgreement(true);
                }
            }
        },
        [boxId, productId, registerLicenceSuccess, userAgreementAccepted]
    );

    const processLinkWithoutEvent = useCallback(() => processLink(), [processLink]);

    const chooseFile = useCallback(() => {
        inputFile.current.click();
    }, [inputFile]);

    const readFile = useCallback(
        (e) => {
            const reader = new FileReader();
            reader.onload = () => {
                registerOfflineLicence(reader.result)
                    .then((response) => {
                        if (Object.prototype.call('hasOwnProperty', 'productId')) {
                            setProductId(response.productId);
                            setOfflineMode(false);
                            setLocation(
                                response.location
                                    ? response.location
                                    : `https://${getLicenceBase()}/offlinelicense/${response.productId}/config/license`
                            );
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(() => {
                        navigate('/onboarding/error');
                    });
            };
            reader.readAsText(e.currentTarget.files[0]);
        },
        [navigate]
    );

    const labels = useMemo(
        () => ({
            title: i18n.tc('onboarding.userAgreement.eula.title'),
            indication: i18n.tc('onboarding.userAgreement.eula.indication'),
            checkBoxLabel: i18n.tc('onboarding.userAgreement.checkbox'),
        }),
        [i18n]
    );

    const handleUserAgreementAccept = useCallback(() => {
        setShowUserAgreement(false);
        setUserAgreementAccepted(true);
        processLink(true);
    }, [processLink]);

    const handleOfflineMode = useCallback(() => {
        setOfflineMode(true);
    }, []);

    return showUserAgreement ? (
        <UserAgreement labels={labels} pdf={pdf} onUserAgreementAccept={handleUserAgreementAccept} />
    ) : (
        <MainSection onEnter={processLink}>
            <MainSectionTitle>{i18n.t('onboarding.link.title')}</MainSectionTitle>
            <MainSectionContent>
                {offlineMode ? (
                    <Typography variant="body2">{`1. ${i18n.tc('onboarding.link.selectFileIndication')}`}</Typography>
                ) : (
                    <Typography variant="body2">{`1. ${i18n.tc('onboarding.link.productIdIndication')}`}</Typography>
                )}
                <Explanation>
                    {offlineMode ? (
                        <Fab onClick={chooseFile} color="primary" size="medium" variant="extended">
                            <StyledExtendedIcon />
                            {i18n.tc('onboarding.link.chooseFile')}
                            <StyledInputFile onChange={readFile} ref={inputFile} type="file" />
                        </Fab>
                    ) : (
                        <Input
                            placeholder={i18n.tc('onboarding.link.productId')}
                            value={productId}
                            onChange={setProductId}
                            name="productId"
                            type="text"
                            error={(formSubmit && productId === '') || productIdError !== null}
                            textError={productIdError}
                            autoFocus
                        />
                    )}
                </Explanation>
                {!offlineMode && (
                    <StyledOfflineButton>
                        <MuiLink variant="body2" component="button" onClick={handleOfflineMode}>
                            Offline mode
                        </MuiLink>
                    </StyledOfflineButton>
                )}
                <Explanation>
                    <Typography variant="body2">{`2. ${i18n.tc('onboarding.link.boxIdIndication')}`}</Typography>
                </Explanation>
                <Explanation>
                    <Input
                        placeholder={i18n.tc('onboarding.link.boxId')}
                        value={boxId}
                        onChange={setBoxId}
                        name="boxId"
                        type="text"
                        error={(formSubmit && boxId === '') || boxIdError !== null}
                        textError={boxIdError}
                    />
                </Explanation>
                <ButtonsGroupWithHelp
                    onBigButtonClick={processLinkWithoutEvent}
                    label={i18n.tc('app.nextButton')}
                    disableBigButton={offlineMode}
                />
            </MainSectionContent>
        </MainSection>
    );
}

export default Link;
