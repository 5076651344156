import React, { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled, Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { SsoContext } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

const StyledTypography = styled(Typography)(({ theme }) => ({
    '& a': {
        color: theme.palette.common.white,
    },
}));

function NoAccess() {
    const i18n = useI18n();
    const { state } = useContext(SsoContext);
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const danger = useMemo(() => ({ __html: i18n.tc('app.noAccess.visit') }), [i18n]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('app.noAccess.title')}</MainSectionTitle>
            <MainSectionContent>
                <Typography gutterBottom variant="body2">
                    {i18n.tc('app.noAccess.signedIn', { email: state.email })}
                </Typography>
                <StyledTypography gutterBottom variant="body2" dangerouslySetInnerHTML={danger} />
                <Typography variant="body2">{i18n.tc('app.noAccess.grant')}</Typography>
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('app.backButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default NoAccess;
