export default class SsoLocalStorage {
    static LANG_KEY = 'braincube-sso-lang';

    static EMAIL_KEY = 'braincube-sso-email';

    static setLang(lang) {
        localStorage.setItem(SsoLocalStorage.LANG_KEY, lang);
    }

    static setEmail(email) {
        localStorage.setItem(SsoLocalStorage.EMAIL_KEY, email);
    }

    static getLang() {
        return localStorage.getItem(SsoLocalStorage.LANG_KEY);
    }

    static getEmail() {
        return localStorage.getItem(SsoLocalStorage.EMAIL_KEY);
    }
}
