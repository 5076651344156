import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button, MobileStepper, Paper, Typography, useTheme, colors, styled } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { useI18n } from '@braincube/i18n';

import { SsoContext } from '../../../../App/contexts';

const steps = (lang, i18n) => {
    const langToUse = lang === 'en-us' ? 'en' : lang;

    return {
        firefox: {
            macos: [
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.macos.one`),
                    imgPath: `1-macos-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.macos.two`),
                    imgPath: `2-macos-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.macos.three`),
                    imgPath: `3-macos-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.macos.four`),
                    imgPath: `4-macos-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.macos.five`),
                    imgPath: `5-macos-firefox-${langToUse}.png`,
                },
            ],
            windows: [
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.windows.one`),
                    imgPath: `1-windows-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.windows.two`),
                    imgPath: `2-windows-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.windows.three`),
                    imgPath: `3-windows-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.windows.four`),
                    imgPath: `4-windows-firefox-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.firefox.windows.five`),
                    imgPath: `5-windows-firefox-${langToUse}.png`,
                },
            ],
        },
        chrome: {
            macos: [
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.one`),
                    imgPath: `1-macos-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.two`),
                    imgPath: `2-macos-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.three`),
                    imgPath: `3-macos-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.four`),
                    imgPath: `4-macos-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.five`),
                    imgPath: `5-macos-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.macos.six`),
                    imgPath: `6-macos-chrome-${langToUse}.png`,
                },
            ],
            windows: [
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.one`),
                    imgPath: `1-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.two`),
                    imgPath: `2-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.three`),
                    imgPath: `3-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.four`),
                    imgPath: `4-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.five`),
                    imgPath: `5-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.six`),
                    imgPath: `6-windows-chrome-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.chrome.windows.seven`),
                    imgPath: `7-windows-chrome-${langToUse}.png`,
                },
            ],
        },
        edge: {
            windows: [
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.one`),
                    imgPath: `1-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.two`),
                    imgPath: `2-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.three`),
                    imgPath: `3-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.four`),
                    imgPath: `4-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.five`),
                    imgPath: `5-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.six`),
                    imgPath: `6-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.seven`),
                    imgPath: `7-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.eight`),
                    imgPath: `8-windows-edge-${langToUse}.png`,
                },
                {
                    label: i18n.tc(`certificate.ticket.steps.edge.windows.nine`),
                    imgPath: `9-windows-edge-${langToUse}.png`,
                },
            ],
        },
    };
};

const StyledTitle = styled(Paper)(({ theme }) => ({
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: theme.spacing(2),
    textAlign: 'left',
    '& p': {
        fontWeight: '500',
    },
}));

const StyledImagesContainer = styled(Paper)({
    background: colors.grey[300],
    '& img': {
        display: 'block',
        margin: '0 auto',
    },
});

const StyledNavigation = styled(MobileStepper)(({ theme }) => ({
    background: theme.palette.common.white,
    color: theme.palette.common.black,
}));

function InstallStepper({ browser, os }) {
    const theme = useTheme();
    const i18n = useI18n();
    const [activeStep, setActiveStep] = useState(0);
    const { state } = useContext(SsoContext);
    const maxSteps = steps(state.lang, i18n)[browser][os].length;
    const navigate = useNavigate();

    const handleNext = useCallback(() => {
        if (activeStep === maxSteps - 1) {
            navigate('/');
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [activeStep, navigate, maxSteps]);

    const handleBack = useCallback(() => {
        if (activeStep === 0) {
            navigate('/certificate');
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }, [activeStep, navigate]);

    const nextButton = useMemo(
        () => (
            <Button size="small" color="secondary" onClick={handleNext}>
                {activeStep === maxSteps - 1
                    ? i18n.tc('certificate.ticket.finish')
                    : i18n.tc('certificate.ticket.next')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
        ),
        [activeStep, handleNext, i18n, maxSteps, theme.direction]
    );

    const backButton = useMemo(
        () => (
            <Button size="small" color="secondary" onClick={handleBack}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {i18n.tc('certificate.ticket.back')}
            </Button>
        ),
        [handleBack, i18n, theme.direction]
    );

    return (
        <>
            <StyledTitle square elevation={0}>
                <Typography variant="subtitle2">{steps(state.lang, i18n)[browser][os][activeStep].label}</Typography>
            </StyledTitle>
            <StyledImagesContainer square elevation={0}>
                <img
                    src={`${process.env.PUBLIC_URL}/${steps(state.lang, i18n)[browser][os][activeStep].imgPath}`}
                    alt={steps(state.lang, i18n)[browser][os][activeStep].label}
                />
            </StyledImagesContainer>
            <StyledNavigation
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={nextButton}
                backButton={backButton}
            />
        </>
    );
}

InstallStepper.propTypes = {
    browser: PropTypes.string.isRequired,
    os: PropTypes.string.isRequired,
};

export default InstallStepper;
