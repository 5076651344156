import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'browser-authenticator';
import QRCode from 'qrcode.react';

import { styled, Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { checkFavoriteApplication, SsoContext, startFetching, stopFetching } from 'App/contexts';
import { checkPin, validateRgpd } from 'services/api/sso';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import Input from 'components/Input';
import { MainSection, MainSectionContent, MainSectionTitle } from 'components/MainSection';
import Gdpr from 'scenes/Account/Gdpr';
import UserAgreement from 'scenes/Onboarding/UserAgreement';

const { Authenticator } = window;

const StyledQrCodeContainer = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
}));

const StyledQrCode = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    height: 150,
    width: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)} auto`,
}));

const StyledMainSection = styled(MainSection, { shouldForwardProp: (prop) => prop !== 'isOtpChange' })(
    ({ isOtpChange }) => ({
        width: isOtpChange ? 540 : 'auto',
    })
);

const GdprComponent = <Gdpr />;

function TwoFactorAuth() {
    const i18n = useI18n();
    const [pin, setPin] = useState('');
    const [responseError, setResponseError] = useState(false);
    const { dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [lastProductUrl, setLastProductUrl] = useState('');
    const [showUserAgreement, setShowUserAgreement] = useState(false);

    const isCertAuth = location.state && location.state.cert;
    const isOtpChange = location.state && location.state.step === 'CHANGE_2FA';
    const seed = location.state && location.state.seed;
    const isNewUser = location.state?.isNewUser === true;

    function successRedirect(message) {
        const parameters = new URLSearchParams(window.location.search);
        const redirectParameter = parameters.get('redirect');

        if (redirectParameter !== null) {
            window.location.assign(redirectParameter);
        } else {
            checkFavoriteApplication(message);
        }
    }

    const handleValidatePin = useCallback(() => {
        setResponseError(false);

        dispatch(startFetching());

        checkPin(isOtpChange, pin, isCertAuth)
            .then((response) => {
                if (!isCertAuth) {
                    response.json().then(({ type, message }) => {
                        if (type === 'BAD_OTP') {
                            dispatch(stopFetching());
                            setResponseError(true);
                        } else if (type === 'PASS_RGPD') {
                            setLastProductUrl(message);
                            setShowUserAgreement(true);
                            dispatch(stopFetching());
                        } else if (type === 'PASS') {
                            if (isNewUser) {
                                dispatch(stopFetching());

                                navigate('/account/create', { state: { redirect: message } });
                            } else if (message) {
                                successRedirect(message);
                            } else {
                                dispatch(stopFetching());
                                navigate('/no-access');
                            }
                        } else if (type === 'PASS_ONBOARDING') {
                            navigate('/onboarding/link');
                        }
                    });
                }
            })
            .catch(() => {
                dispatch(stopFetching());
                setResponseError(true);
            });
    }, [dispatch, navigate, isCertAuth, isNewUser, isOtpChange, pin]);

    const labels = useMemo(
        () => ({
            title: i18n.tc('onboarding.userAgreement.rgpd.title'),
            indication: i18n.tc('onboarding.userAgreement.rgpd.indication'),
            checkBoxLabel: i18n.tc('onboarding.userAgreement.checkbox'),
            postpone: i18n.tc('onboarding.userAgreement.postpone'),
        }),
        [i18n]
    );

    const handleUserAgreementAccept = useCallback(() => {
        validateRgpd().then(() => successRedirect(lastProductUrl));
        setShowUserAgreement(false);
    }, [lastProductUrl]);

    const handleUserAgreementPostpone = useCallback(() => {
        successRedirect(lastProductUrl);
        setShowUserAgreement(false);
    }, [lastProductUrl]);

    return showUserAgreement ? (
        <UserAgreement
            labels={labels}
            content={GdprComponent}
            onUserAgreementAccept={handleUserAgreementAccept}
            onUserAgreementPostpone={handleUserAgreementPostpone}
        />
    ) : (
        <StyledMainSection onEnter={handleValidatePin} isOtpChange={isOtpChange}>
            {isOtpChange ? (
                <MainSectionTitle>{i18n.tc('challenge.2faChange.title')}</MainSectionTitle>
            ) : (
                <MainSectionTitle>{i18n.tc('challenge.2fa.title')}</MainSectionTitle>
            )}
            <MainSectionContent>
                <Explanation>
                    {isOtpChange ? (
                        <StyledQrCodeContainer>
                            <Typography variant="body2">{i18n.tc('challenge.2faChange.explanation')}</Typography>
                            <StyledQrCode>
                                <QRCode
                                    value={Authenticator.generateTotpUri(
                                        seed.key,
                                        seed.account,
                                        window.BC_API_ENDPOINTS_CONF.sso,
                                        seed.algorithm,
                                        seed.pinLength,
                                        seed.period
                                    )}
                                />
                            </StyledQrCode>
                            <Typography variant="body2">{i18n.tc('challenge.2faChange.help')}</Typography>
                            <ul>
                                <li>
                                    <Typography variant="body2">
                                        {`${i18n.tc('challenge.2faChange.account')} :${seed.account}`}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">
                                        {`${i18n.tc('challenge.2faChange.key')} :${seed.key}`}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">{i18n.tc('challenge.2faChange.timeBased')}</Typography>
                                </li>
                            </ul>
                        </StyledQrCodeContainer>
                    ) : (
                        <Typography variant="body2">{i18n.tc('challenge.2fa.explanation')}</Typography>
                    )}
                </Explanation>
                <Explanation>
                    <Input
                        onChange={setPin}
                        type="number"
                        value={pin}
                        placeholder={i18n.tc('challenge.2fa.pinPlaceholder')}
                        autoFocus
                        name="pin"
                        error={responseError}
                        textError={responseError ? i18n.tc('challenge.2fa.invalidCode') : null}
                    />
                </Explanation>
                <ButtonsGroupWithHelp
                    onBigButtonClick={handleValidatePin}
                    label={i18n.tc('challenge.2fa.verifyCode')}
                />
            </MainSectionContent>
        </StyledMainSection>
    );
}

export default TwoFactorAuth;
