import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state/index';

import { Fab, Popover, Tooltip, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import {
    Brazil,
    France,
    Germany,
    Italy,
    Netherlands,
    RepublicOfPoland,
    Russia,
    Spain,
    UnitedKingdom,
    UnitedStatesOfAmerica,
} from '@braincube/svg';

import { SsoContext, setLang, availableLangs } from 'App/contexts';

const StyledLangButton = styled('li', { shouldForwardProp: (prop) => prop !== 'active' })(({ theme, active }) => ({
    outline: 'none',
    height: 40,
    color: active ? theme.palette.common.white : theme.palette.common.black,
    backgroundColor: active ? theme.palette.secondary.main : 'rgba(255, 255, 255, 0.6)',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: active ? theme.palette.secondary.dark : '#c0d0d3',
    },
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
    '& > svg': {
        margin: theme.spacing(0, 1),
    },
}));

const popperProps = {
    disablePortal: true,
};

const getLangIcon = (lang) => {
    switch (lang) {
        case 'de':
            return <Germany />;
        case 'en':
            return <UnitedKingdom />;
        case 'en-us':
            return <UnitedStatesOfAmerica />;
        case 'es':
            return <Spain />;
        case 'fr':
            return <France />;
        case 'it':
            return <Italy />;
        case 'nl':
            return <Netherlands />;
        case 'pl':
            return <RepublicOfPoland />;
        case 'pt-br':
            return <Brazil />;
        case 'ru':
            return <Russia />;
        default:
            return <UnitedKingdom />;
    }
};

function renderListElem(lang) {
    let label = '';

    switch (lang) {
        case 'fr':
            label = 'Français';
            break;
        case 'en-us':
            label = 'English US';
            break;
        default:
            label = 'English';
    }

    return <ListElem key={lang} lang={lang} label={label} />;
}

function ListElem({ lang, label }) {
    const { state, dispatch } = useContext(SsoContext);
    const handleClick = useCallback(() => dispatch(setLang(lang)), [dispatch, lang]);
    return (
        <StyledLangButton active={lang === state.lang} onClick={handleClick}>
            {getLangIcon(lang)} {label}
        </StyledLangButton>
    );
}

ListElem.propTypes = {
    lang: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
}));

const StyledList = styled('ul')({
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: '#FFF',
});

const StyledPopover = styled(Popover)({
    '& .MuiPopover-paper': {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
    },
});

const StyledFab = styled(Fab)({
    '& > svg': {
        width: '100%',
        height: '100%',
    },
});

function LangSwitcher() {
    const { state } = useContext(SsoContext);
    const i18n = useI18n();

    return (
        <PopupState variant="popover">
            {(popupState) => (
                <>
                    <StyledTooltip
                        title={i18n.tc('app.languageTooltip')}
                        disableFocusListener
                        PopperProps={popperProps}
                    >
                        <StyledFab {...bindTrigger(popupState)} size="small" color="secondary" aria-label="Lang">
                            {getLangIcon(state.lang)}
                        </StyledFab>
                    </StyledTooltip>
                    <StyledPopover {...bindPopover(popupState)}>
                        <StyledList onClick={popupState.close}>{availableLangs.map(renderListElem)}</StyledList>
                    </StyledPopover>
                </>
            )}
        </PopupState>
    );
}

export default LangSwitcher;
