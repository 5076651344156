import { fetchWrapper } from 'services/api/sso';

function updateProduct(product) {
    return fetchWrapper(
        `api/v2/provider/${product.providerName}/product/${product.productId}`,
        'PUT',
        JSON.stringify(product),
        new Headers({ 'Content-Type': 'application/json' })
    );
}

export default updateProduct;
