import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import EmailButton from 'components/EmailButton';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import Input from 'components/Input';
import { MainSection, MainSectionContent, MainSectionTitle } from 'components/MainSection';
import { getEmailFromConfirmCode, postNewMail } from 'scenes/EmailChange/services/api';

function EmailChange() {
    const i18n = useI18n();
    const [currentPassword, setCurrentPassword] = useState('');
    const [email, setEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [mailOk, setMailOk] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const confirmCode = useMemo(() => searchParams.get('confirmCode'), [searchParams]);

    useEffect(() => {
        if (!confirmCode) {
            navigate('/');
        }
    }, [confirmCode, navigate]);

    const handleChangeCurrentPassword = useCallback((newValue) => {
        setCurrentPassword(newValue);
    }, []);

    const handleChangeNewEmail = useCallback((newValue) => {
        setNewEmail(newValue);
    }, []);

    const confirmNewEmail = useCallback(() => {
        setFormSubmit(true);

        postNewMail(confirmCode, currentPassword, newEmail)
            .then(() => {
                setMailOk(true);
            })
            .catch(() => {
                navigate('/');
            });
    }, [confirmCode, currentPassword, navigate, newEmail]);

    useEffect(() => {
        getEmailFromConfirmCode(confirmCode)
            .then((response) => {
                setEmail(response.previous);
            })
            .catch(() => {
                navigate('/');
            });
    }, [confirmCode, navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.tc('email.change.title')}</MainSectionTitle>
            <MainSectionContent>
                {!mailOk ? (
                    <>
                        <Stack spacing={2} direction="column">
                            <EmailButton email={email} />
                            <Input
                                name="currentPassword"
                                onChange={handleChangeCurrentPassword}
                                type="password"
                                value={currentPassword}
                                placeholder={i18n.tc('email.change.currentPassword')}
                                error={formSubmit && currentPassword === ''}
                                autoFocus
                                required
                            />
                            <Input
                                name="newEmail"
                                onChange={handleChangeNewEmail}
                                type="email"
                                value={newEmail}
                                placeholder={i18n.tc('email.change.newEmail')}
                                error={formSubmit && newEmail === ''}
                                required
                            />
                        </Stack>
                        <ButtonsGroupWithHelp
                            label={i18n.tc('email.change.confirmNewEmail')}
                            onBigButtonClick={confirmNewEmail}
                            disableBigButton={currentPassword === '' || newEmail === ''}
                        />
                    </>
                ) : (
                    <Stack spacing={2} direction="column">
                        <Typography>{i18n.tc('email.change.emailSent')}</Typography>
                        <EmailButton email={newEmail} />
                    </Stack>
                )}
            </MainSectionContent>
        </MainSection>
    );
}

export default EmailChange;
