import Cookies from 'js-cookie';

import { fetchWithBearer } from '@braincube/jwt-browser';

import { checkFavoriteApplication } from 'App/contexts';

export const PREFERRED_CONNECTION_MODE_COOKIE_NAME = 'preferredConnectionMode';
export function getPreferredConnectionModeCookieOptions() {
    return { sameSite: 'Lax', domain: `.${window.BC_API_ENDPOINTS_CONF.sso}`, expires: 30 };
}

/**
 * Retrieving the license server url only available at maxnod and in our staging environment.
 * (Fuck the dev environment)
 * @returns {string} licence server basename
 */
export const getLicenceBase = () => {
    if (window.location.hostname.indexOf('test.mybraincube.com') !== -1) {
        return 'b3iotlicense.test.mybraincube.com';
    }
    return 'b3iotlicense.mybraincube.com';
};

export function fetchWrapper(path, method, body = null, headers = undefined) {
    return fetch(`https://${window.BC_API_ENDPOINTS_CONF.sso}/sso-server/${path}`, {
        credentials: 'include',
        method,
        body,
        headers,
    }).then((response) => {
        if (response.ok) {
            return Promise.resolve(response);
        }
        return Promise.reject(response);
    });
}

export function registerLicence(productId, boxId) {
    return fetchWrapper(
        'api/v2/register/licence',
        'POST',
        `productId=${encodeURIComponent(productId)}&keyId=${boxId}`,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function getLicenceKeyFromLocation(location, productId) {
    return fetchWithBearer(`https://${getLicenceBase()}/offlinelicense/${productId}/config/license`, {
        method: 'POST',
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject();
    });
}

export function registerOfflineLicence(iotRequest) {
    return fetchWithBearer(`https://${getLicenceBase()}/offlinelicense/register`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
            iotRequest: iotRequest.trim(),
        }),
    }).then((response) => {
        if (response.status === 201) {
            const location = response.headers.get('Location');
            // eslint-disable-next-line no-useless-escape
            const uuid = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.exec(
                location
            )[0];

            return Promise.resolve({ uuid, location });
        }
        if (response.status === 200) {
            return response.json();
        }
        return Promise.reject();
    });
}

export function getLicence() {
    return fetchWrapper('api/v2/register/licence', 'GET');
}

export function getUser(mail) {
    return fetchWrapper(`api/v2/user/email/${mail}`, 'GET');
}

export function createUser(mail) {
    const name = mail.split('@');
    return fetchWrapper(
        'ws/login/create',
        'POST',
        JSON.stringify({
            email: mail,
            firstName: name[0],
            lastName: name[0],
        }),
        new Headers({ 'Content-Type': 'application/json' })
    );
}

export function modifyUser(mail, user) {
    return fetchWrapper(
        `api/v2/user/${mail}`,
        'PUT',
        JSON.stringify(user),
        new Headers({ 'Content-Type': 'application/json' })
    );
}

export function board(boxId, mail, productId, redirect) {
    let body = `keyId=${encodeURIComponent(boxId)}&mail=${encodeURIComponent(mail)}`;
    if (productId) {
        body += `&productId=${encodeURIComponent(productId)}`;
    }
    return fetchWrapper(
        `ws/login/onboard?redirect=${encodeURIComponent(redirect)}`,
        'POST',
        body,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function getPasswordRules(passwordTicket) {
    if (passwordTicket) {
        return fetchWrapper(`ws/login/password/reset?passwordTicket=${passwordTicket}`, 'GET');
    }
    return fetchWrapper('ws/login/password/reset', 'GET');
}

export function resetPassword(password, passwordTicket) {
    let body = `password=${encodeURIComponent(password)}`;

    if (passwordTicket) {
        body += `&passwordTicket=${encodeURIComponent(passwordTicket)}`;
    }

    return fetchWrapper(
        'ws/login/password/reset',
        'POST',
        body,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function recovery() {
    return fetchWrapper(
        'ws/login/password/recovery',
        'POST',
        null,
        new Headers({ 'Content-Type': 'application/json' })
    );
}

export function challenge(password) {
    return fetchWrapper(
        'ws/login/challenge',
        'POST',
        `password=${encodeURIComponent(password)}`,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function lookup(mail, locationSearch) {
    return fetchWrapper(
        `ws/login/lookup${locationSearch || ''}`,
        'POST',
        `mail=${encodeURIComponent(mail)}`,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function getState() {
    return fetchWrapper('ws/session/state', 'GET');
}

export function me() {
    return fetchWrapper('ws/user/me');
}

export function loginWithCert(pinCode) {
    return (
        fetch(
            `https://certify.${window.BC_API_ENDPOINTS_CONF.base}/sso-server/ws/session/openWithCert${
                pinCode ? `?pinCode=${pinCode}` : ''
            }`,
            {
                method: 'POST',
                credentials: 'include',
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            // eslint-disable-next-line consistent-return
            .then((response) => {
                if (response.accessList.length > 0) {
                    Cookies.set(
                        PREFERRED_CONNECTION_MODE_COOKIE_NAME,
                        'certificate',
                        getPreferredConnectionModeCookieOptions()
                    );

                    const parameters = new URLSearchParams(window.location.search);
                    const redirectParameter = parameters.get('redirect');

                    if (redirectParameter !== null) {
                        window.location.assign(redirectParameter);
                    } else {
                        checkFavoriteApplication(response.accessList[0].product.url);
                    }
                } else {
                    return Promise.reject(response);
                }
            })
    );
}

export function getCdnOrigin() {
    if (process.env.NODE_ENV === 'production') {
        let { hostname } = window.location;

        if (hostname.includes('.blu-e.fr')) {
            hostname = 'blu-e.fr';
        }

        return `https://cdn.${hostname}`;
    }

    return process.env.REACT_APP_ENV;
}

export function getBrandingTheme() {
    return fetch(`${getCdnOrigin()}/branding/theme.json`);
}

export function checkPin(isOtpChange, pinCode, isCert) {
    if (isOtpChange) {
        return fetchWrapper(
            'ws/login/2fa/reset',
            'POST',
            `pinCode=${pinCode}`,
            new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        );
    }
    if (isCert) {
        return loginWithCert(pinCode);
    }
    return fetchWrapper(
        'ws/login/2fa',
        'POST',
        `pinCode=${pinCode}`,
        new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
    );
}

export function validateRgpd() {
    return fetchWrapper('api/v2/user/validateRgpd', 'POST');
}

export function checkIfUserMustValidateRgpd() {
    return fetchWrapper('api/v2/user/mustValidateRgpd', 'GET');
}

export function modifyUserPreference(preference, value) {
    return fetch(`https://${window.BC_API_ENDPOINTS_CONF.preference}/users/general/${preference}`, {
        credentials: 'include',
        body: value,
        headers: new Headers({ 'Content-Type': 'text/plain', 'x-app-key': btoa(process.env.REACT_APP_PACKAGE_NAME) }),
        method: 'PUT',
    });
}

export function checkCertificateTicket(ticket) {
    if (ticket) {
        return fetchWrapper(`ws/certificate?code=${ticket}`, 'GET');
    }
    return fetchWrapper('ws/certificate', 'GET');
}

export function downloadCertificate(ticket) {
    return fetchWrapper(`ws/certificate?code=${ticket}`, 'POST');
}

export function checkOnboardingCode(code) {
    return fetchWrapper(`ws/transfer/info/${code}`, 'GET');
}

export function onboardBraincubeTransfer(name, tampixUuid, code, forceOnboarding) {
    let apiUrl = `api/v2/transfer/${code}`;

    if (forceOnboarding) {
        apiUrl = `api/v2/transfer/${code}?force=true`;
    }

    return fetchWrapper(
        apiUrl,
        'POST',
        JSON.stringify({
            braincubeTransferName: name,
            tampixProductId: tampixUuid,
        }),
        new Headers({ 'Content-Type': 'application/json' })
    );
}
