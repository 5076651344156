import PropTypes from 'prop-types';
import React from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';

function Loader({ message }) {
    return (
        <Stack direction="column" spacing={2} alignItems="center">
            <CircularProgress size={40} />
            <Typography>{message}</Typography>
        </Stack>
    );
}

Loader.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Loader;
