import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useI18n } from '@braincube/i18n';

import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import {
    loginWithCert,
    PREFERRED_CONNECTION_MODE_COOKIE_NAME,
    getPreferredConnectionModeCookieOptions,
} from 'services/api/sso';
import { MainSection, MainSectionContent, MainSectionTitle } from 'components/MainSection';

let retryCount = 0;

export const handleCertLogin = (dispatch, navigate, location) => {
    dispatch(startFetching());

    loginWithCert().catch((response) => {
        if (Cookies.get(PREFERRED_CONNECTION_MODE_COOKIE_NAME)) {
            Cookies.remove(PREFERRED_CONNECTION_MODE_COOKIE_NAME, getPreferredConnectionModeCookieOptions());
        }

        dispatch(stopFetching());

        if ((response.status < 100 || response.status >= 500) && retryCount < 4) {
            retryCount += 1;
            handleCertLogin(dispatch, navigate, location);
            return;
        }

        if (response.status === 401) {
            response.json().then(({ type }) => {
                if (type === 'OTP_NEEDED') {
                    navigate({ pathname: '/challenge/2fa', search: location.search }, { state: { cert: true } });
                } else if (type === 'CHANGE_2FA') {
                    navigate('/certify/mandatory-twofactor-auth');
                } else {
                    navigate('/certify/invalid');
                }
            });
        }

        navigate('/certify/invalid');
    });
};

function Certify() {
    const i18n = useI18n();
    const { dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        handleCertLogin(dispatch, navigate, location);
    }, [dispatch, navigate, location]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('certify.title')}</MainSectionTitle>
            <MainSectionContent>{i18n.tc('certify.text')}</MainSectionContent>
        </MainSection>
    );
}

export default Certify;
