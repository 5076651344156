import React from 'react';
import PropTypes from 'prop-types';

import { Chip, Avatar, styled } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

const StyledChip = styled(Chip)({
    width: '100%',
    '& .MuiChip-label': {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
});

const avatar = (
    <Avatar>
        <FaceIcon />
    </Avatar>
);

function EmailButton({ onClick, email }) {
    return <StyledChip avatar={avatar} label={email} onClick={onClick} onDelete={onClick} color="secondary" />;
}

EmailButton.propTypes = {
    email: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

EmailButton.defaultProps = {
    onClick: null,
};
export default EmailButton;
