import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import Bowser from 'bowser';

import { Fade, Grid, Paper, Typography, Button, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    padding: theme.spacing(1, 2),
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
}));

const StyledHelpButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const StyledGrid = styled(Grid)({
    alignItems: 'center',
});

function IEBanner() {
    const [isDisplayed, setDisplayed] = useState(true);
    const i18n = useI18n();
    const navigate = useNavigate();

    const close = useCallback(() => {
        setDisplayed(false);
        Cookies.set('sso-ie-check', true, { expires: 7 });
    }, []);

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isInvalidBrowser = browser.satisfies({
        'internet explorer': '<=11',
        safari: '>0',
    });

    const handleClick = useCallback(() => {
        navigate('/help#recommended-browsers');
    }, [navigate]);

    if (Cookies.get('sso-ie-check') || !isInvalidBrowser) {
        return null;
    }

    return (
        <Fade in={isDisplayed}>
            <StyledPaper>
                <StyledGrid container justifyContent="space-between">
                    <Grid item>
                        <StyledTypography>{i18n.tc('app.ieCheck.title')}</StyledTypography>
                    </Grid>
                    <Grid item>
                        <Button color="secondary" onClick={close}>
                            {i18n.tc('app.ieCheck.button')}
                        </Button>
                        <StyledHelpButton color="secondary" onClick={handleClick}>
                            {i18n.tc('app.helpTooltip')}
                        </StyledHelpButton>
                    </Grid>
                </StyledGrid>
            </StyledPaper>
        </Fade>
    );
}

export default IEBanner;
