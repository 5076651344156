import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function NoMatch() {
    const i18n = useI18n();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <MainSection>
            <MainSectionTitle>404</MainSectionTitle>
            <MainSectionContent>
                <Typography variant="body2">{i18n.tc('app.notFound', { pathname: location.pathname })}</Typography>
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('app.backButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default NoMatch;
