/* eslint-disable react-perf/jsx-no-jsx-as-prop */

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Challenge from 'scenes/Challenge';
import TwoFactorAuth from 'scenes/Challenge/TwoFactorAuth';
import EmailChange from 'scenes/EmailChange';
import EmailChangeConfirm from 'scenes/EmailChange/Confirm';
import Help from 'scenes/Help';
import Certify from 'scenes/Certify';
import InvalidCertificate from 'scenes/InvalidCertificate';
import MandatoryTwoFactorAuth from 'scenes/InvalidCertificate/MandatoryTwoFactorAuth';
import NoAccess from 'scenes/NoAccess';
import Lookup from 'scenes/Lookup';
import NoMatch from 'scenes/NoMatch';
import Onboarding from 'scenes/Onboarding';
import Account from 'scenes/Account';
import AccountPreference from 'scenes/Account/Preference';
import AccountCreateConfirm from 'scenes/Onboarding/Confirm';
import AccessList from 'scenes/AccessList';
import OnboardingLink from 'scenes/Onboarding/Link';
import OnboardingSite from 'scenes/Onboarding/Site';
import OnboardingRequest from 'scenes/Onboarding/Request';
import OnboardingError from 'scenes/Onboarding/Error';
import Password from 'scenes/Password';
import PasswordChange from 'scenes/Password/Expire';
import PasswordInvalidTicket from 'scenes/Password/InvalidTicket';
import Recovery from 'scenes/Recovery';
import RecoveryConfirm from 'scenes/Recovery/Confirm';
import RecoveryError from 'scenes/Recovery/Error';
import Certificate from 'scenes/Certificate';
import BraincubeTransferOnboarding from 'scenes/BraincubeTransferOnboarding';

import ProtectedRoute from '../ProtectedRoute';
import HowToInstall from '../../scenes/HowToInstall';

function SsoRouter() {
    return (
        <Routes>
            <Route path="/" element={<Lookup />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/onboarding/request" element={<OnboardingRequest />} />
            <Route path="/onboarding/link" element={<OnboardingLink />} />
            <Route path="/onboarding/site" element={<OnboardingSite />} />
            <Route path="/onboarding/error" element={<OnboardingError />} />
            <Route path="/help" element={<Help />} />
            <Route path="/certify" element={<Certify />} />
            <Route path="/certify/invalid" element={<InvalidCertificate />} />
            <Route path="/certify/mandatory-twofactor-auth" element={<MandatoryTwoFactorAuth />} />
            <Route path="/no-access" element={<NoAccess />} />
            <Route path="/terms-and-conditions" element={<AccountPreference />} />

            <Route path="/challenge" element={<ProtectedRoute component={<Challenge />} />} />
            <Route path="/challenge/2fa" element={<TwoFactorAuth />} />
            <Route path="/2fa/change" element={<TwoFactorAuth />} />
            <Route path="/recovery" element={<ProtectedRoute component={<Recovery />} />} />
            <Route path="/recovery/confirm" element={<ProtectedRoute component={<RecoveryConfirm />} />} />
            <Route path="/recovery/error" element={<ProtectedRoute component={<RecoveryError />} />} />
            <Route path="/account/create" element={<ProtectedRoute component={<Account />} />} />
            <Route path="/account/create/preference" element={<ProtectedRoute component={<AccountPreference />} />} />
            <Route path="/onboarding/confirm" element={<ProtectedRoute component={<AccountCreateConfirm />} />} />
            <Route path="/access-list" element={<ProtectedRoute component={<AccessList />} />} />
            <Route path="/password" element={<Password />} />
            <Route path="/creation" element={<Password />} />
            <Route path="/password/change" element={<PasswordChange />} />
            <Route path="/password/invalid-ticket" element={<PasswordInvalidTicket />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/certificate/how-to-install" element={<HowToInstall />} />
            <Route path="/email/change" element={<EmailChange />} />
            <Route path="/email/change/confirm" element={<EmailChangeConfirm />} />

            <Route path="/braincubeTransferOnboarding" element={<BraincubeTransferOnboarding />} />

            <Route element={<NoMatch />} />
        </Routes>
    );
}

export default SsoRouter;
