import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { SsoContext, emailIsRequiredForNextStep } from 'App/contexts';

function renderRedirect() {
    return <Navigate to="/" />;
}

function ProtectedRoute({ component: Component }) {
    const { state, dispatch } = useContext(SsoContext);

    if (state.email === '') {
        dispatch(emailIsRequiredForNextStep());
    }

    return state.email !== '' ? Component : renderRedirect;
}

ProtectedRoute.propTypes = {
    component: PropTypes.element.isRequired,
};

export default ProtectedRoute;
