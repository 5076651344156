import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function Expire() {
    const i18n = useI18n();
    const navigate = useNavigate();

    const handleClick = useCallback(() => navigate('/password'), [navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('password.change.title')}</MainSectionTitle>
            <MainSectionContent>
                <div>
                    <Typography variant="body2">{i18n.tc('password.change.expires')}</Typography>
                </div>
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('app.nextButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Expire;
