import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const globalInputProps = {
    spellCheck: false,
    autoComplete: 'off',
};

function Input({ type, placeholder, value, textError, onChange, error, name, autoFocus, inputProps, ...rest }) {
    const handleChange = useCallback(
        (event) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    const InputProps = useMemo(
        () => ({
            ...inputProps,
            ...globalInputProps,
        }),
        [inputProps]
    );

    return (
        <TextField
            type={type}
            label={placeholder}
            value={value}
            onChange={handleChange}
            name={name}
            autoFocus={autoFocus}
            fullWidth
            error={error}
            helperText={textError}
            {...rest}
            InputProps={InputProps}
        />
    );
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    textError: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.bool,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputProps: PropTypes.shape({}),
};

Input.defaultProps = {
    placeholder: null,
    textError: null,
    value: '',
    error: false,
    name: null,
    autoFocus: false,
    inputProps: {},
};

export default Input;
