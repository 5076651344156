import React, { useContext } from 'react';

import { LinearProgress, styled } from '@mui/material';

import { SsoContext } from 'App/contexts';

const StyledLinearProgress = styled(LinearProgress)({
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: 'black',
    },
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#FFDD00',
    },
});

function Progress() {
    const { state } = useContext(SsoContext);

    if (state.isFetching) {
        return <StyledLinearProgress />;
    }
    return null;
}

export default Progress;
