import React from 'react';

import { styled } from '@mui/material';

const StyledExplanation = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

function Explanation({ children }) {
    return <StyledExplanation>{children}</StyledExplanation>;
}

export default Explanation;
