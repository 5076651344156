import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Typography, Fab, styled, FormControlLabel } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import useShowGdpr from 'services/hooks/useShowGdpr';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledMainSection = styled(MainSection)({
    width: 540,
});

const StyledPdfContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isGdprConsultationMode' })(
    ({ theme, isGdprConsultationMode }) => ({
        backgroundColor: theme.palette.common.white,
        height: 250,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: isGdprConsultationMode ? 0 : theme.spacing(2),
        padding: theme.spacing(2),
    })
);

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledPostponeContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
});

function UserAgreement({ labels, pdf, content, onUserAgreementAccept, onUserAgreementPostpone }) {
    const [numPagess, setNumPages] = useState(null);
    const [userAgreementAccepted, setUserAgreementAccepted] = useState(false);
    const scrollable = useRef(null);
    const i18n = useI18n();
    const navigate = useNavigate();

    const isGdprConsultationMode = useShowGdpr();

    const handleChange = useCallback((e) => {
        setUserAgreementAccepted(e.target.checked);
    }, []);

    const handleLoadSuccess = useCallback(({ numPages }) => setNumPages(numPages), []);

    const checkbox = useMemo(() => <Checkbox checked={userAgreementAccepted} />, [userAgreementAccepted]);

    const returnToLogin = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <StyledMainSection>
            <MainSectionTitle>{labels.title}</MainSectionTitle>
            <MainSectionContent>
                {!isGdprConsultationMode && (
                    <Typography variant="body2" gutterBottom>
                        {labels.indication}
                    </Typography>
                )}
                <StyledPdfContainer
                    ref={scrollable}
                    data-testid="pdf-container"
                    isGdprConsultationMode={isGdprConsultationMode}
                >
                    {pdf ? (
                        <Document file={pdf} onLoadSuccess={handleLoadSuccess}>
                            {Array.from(new Array(numPagess), (el, index) => (
                                <Page width={490} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    ) : (
                        content
                    )}
                </StyledPdfContainer>

                {isGdprConsultationMode ? (
                    <ButtonsGroupWithHelp onBigButtonClick={returnToLogin} label={i18n.t('app.backButton')} />
                ) : (
                    <>
                        <StyledFormControlLabel
                            control={checkbox}
                            onChange={handleChange}
                            label={labels.checkBoxLabel}
                        />
                        <ButtonsGroupWithHelp
                            disableBigButton={!userAgreementAccepted}
                            onBigButtonClick={onUserAgreementAccept}
                            label={i18n.t('app.nextButton')}
                        />
                    </>
                )}

                {!isGdprConsultationMode && <br />}
                {onUserAgreementPostpone !== null && (
                    <StyledPostponeContainer>
                        <Fab variant="extended" color="primary" size="medium" onClick={onUserAgreementPostpone}>
                            {labels.postpone}
                        </Fab>
                    </StyledPostponeContainer>
                )}
            </MainSectionContent>
        </StyledMainSection>
    );
}

UserAgreement.propTypes = {
    pdf: PropTypes.string,
    content: PropTypes.elementType,
    onUserAgreementAccept: PropTypes.func.isRequired,
    onUserAgreementPostpone: PropTypes.func,
    labels: PropTypes.shape({
        title: PropTypes.string.isRequired,
        indication: PropTypes.string.isRequired,
        checkBoxLabel: PropTypes.string.isRequired,
        postpone: PropTypes.string,
    }).isRequired,
};

UserAgreement.defaultProps = {
    onUserAgreementPostpone: null,
    pdf: null,
    content: null,
};

export default UserAgreement;
