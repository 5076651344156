import React, { useState, useContext, useEffect, useReducer, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography, InputLabel, FormControl, Select, MenuItem, styled } from '@mui/material';

import { useI18n, LocalesMapper } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import EmailButton from 'components/EmailButton';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import UserAgreement from 'scenes/Onboarding/UserAgreement';
import { modifyUserPreference, checkIfUserMustValidateRgpd, validateRgpd } from 'services/api/sso';
import Gdpr from 'scenes/Account/Gdpr';
import useShowGdpr from 'services/hooks/useShowGdpr';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(2),
    minWidth: 200,
}));

function dateTimePattern(pattern) {
    const today = new Date();
    return format(today, pattern);
}

const langs = [
    { label: 'Deutsch', locale: LocalesMapper.LOCALES.DE },
    { label: 'English', locale: LocalesMapper.LOCALES.EN },
    { label: 'English US', locale: LocalesMapper.LOCALES.EN_US },
    { label: 'Español', locale: LocalesMapper.LOCALES.ES },
    { label: 'Français', locale: LocalesMapper.LOCALES.FR },
    { label: 'Italiano', locale: LocalesMapper.LOCALES.IT },
    { label: 'Nederlands', locale: LocalesMapper.LOCALES.NL },
    { label: 'Polski', locale: LocalesMapper.LOCALES.PL },
    { label: 'Português BR', locale: LocalesMapper.LOCALES.PT_BR },
    { label: 'Türkçe', locale: LocalesMapper.LOCALES.TR },
    { label: 'Русский', locale: LocalesMapper.LOCALES.RU },
];

const USER_AGREEMENT_ACTIONS = {
    SHOW: 'show',
    HIDE: 'hide',
};

const GdprComponent = <Gdpr />;

function userAgreementReducer(state, action) {
    switch (action.type) {
        case USER_AGREEMENT_ACTIONS.SHOW:
            return { ...state, show: true };

        case USER_AGREEMENT_ACTIONS.HIDE:
            return { ...state, show: false };

        default:
            return state;
    }
}

function Preference() {
    const { state, dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [dateFormat, setDateFormat] = useState('dd/MM/yy');
    const [timeFormat, setTimeFormat] = useState('HH:mm');
    const [locale, setLocale] = useState('fr');

    const [userAgreement, dispatchUserAgreement] = useReducer(userAgreementReducer, { show: false });

    const isGdprConsultationMode = useShowGdpr();

    const i18n = useI18n();

    const successRedirect = useCallback(
        (message) => {
            if (message === null) {
                navigate('/no-access');
            } else {
                window.location.assign(message);
            }
        },
        [navigate]
    );

    const processCreate = useCallback(() => {
        if (dateFormat && dateFormat !== '' && timeFormat && timeFormat !== '' && locale && locale !== '') {
            dispatch(startFetching());
            modifyUserPreference('dateFormat', dateFormat)
                .then((dateResponse) => {
                    if (dateResponse.status === 200) {
                        return modifyUserPreference('timeFormat', timeFormat);
                    }
                    return Promise.reject();
                })
                .then((timeResponse) => {
                    if (timeResponse.status === 200) {
                        return modifyUserPreference('locale', locale);
                    }
                    return Promise.reject();
                })
                .then((localeResponse) => {
                    dispatch(stopFetching());

                    if (localeResponse.status === 200) {
                        if (location.state.onboarding === true) {
                            return navigate('/onboarding/link', { state: { isNewUser: true } });
                        }
                        return successRedirect(location.state.redirect);
                    }
                    return Promise.reject();
                })
                .catch(() => {
                    dispatch(stopFetching());
                    return successRedirect(location.state.redirect);
                });
        }
    }, [dateFormat, dispatch, navigate, location, locale, successRedirect, timeFormat]);

    useEffect(() => {
        if (isGdprConsultationMode) {
            dispatchUserAgreement({ type: USER_AGREEMENT_ACTIONS.SHOW });
        } else {
            checkIfUserMustValidateRgpd().then((response) => {
                if (response.status === 200) {
                    dispatchUserAgreement({ type: USER_AGREEMENT_ACTIONS.SHOW });
                }
                if (response.status === 204) {
                    dispatchUserAgreement({ type: USER_AGREEMENT_ACTIONS.HIDE });
                }
            });
        }
    }, [isGdprConsultationMode]);

    const labels = useMemo(
        () => ({
            title: i18n.tc('onboarding.userAgreement.rgpd.title'),
            indication: i18n.tc('onboarding.userAgreement.rgpd.indication'),
            checkBoxLabel: i18n.tc('onboarding.userAgreement.checkbox'),
        }),
        [i18n]
    );

    const handleUserAgreementAccept = useCallback(() => {
        validateRgpd().then(() => dispatchUserAgreement({ type: USER_AGREEMENT_ACTIONS.HIDE }));
    }, []);

    const handleLocalChange = useCallback((event) => {
        setLocale(event.target.value);
    }, []);

    const handleDateFormatChange = useCallback((event) => {
        setDateFormat(event.target.value);
    }, []);

    const handleTimeFormatChange = useCallback((event) => {
        setTimeFormat(event.target.value);
    }, []);

    return userAgreement.show ? (
        <UserAgreement labels={labels} content={GdprComponent} onUserAgreementAccept={handleUserAgreementAccept} />
    ) : (
        <MainSection onEnter={processCreate}>
            <MainSectionTitle>{i18n.t('account.create.title')}</MainSectionTitle>
            <MainSectionContent>
                <EmailButton email={state.email} />
                <Explanation>
                    <Typography variant="body2">{i18n.tc('account.preference.indication')}</Typography>
                </Explanation>
                <div>
                    <StyledFormControl>
                        <InputLabel>{i18n.tc('account.preference.localePlaceholder')}</InputLabel>
                        <Select
                            name="locale"
                            value={locale}
                            onChange={handleLocalChange}
                            label={i18n.tc('account.preference.localePlaceholder')}
                        >
                            {langs.map((found) => (
                                <MenuItem key={found.label} value={found.locale}>
                                    {found.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                </div>
                <div>
                    <StyledFormControl>
                        <InputLabel>{i18n.tc('account.preference.datePlaceholder')}</InputLabel>
                        <Select
                            value={dateFormat}
                            onChange={handleDateFormatChange}
                            label={i18n.tc('account.preference.datePlaceholder')}
                        >
                            <MenuItem value="dd/MM/yy">{`dd/MM/yy (${dateTimePattern('dd/MM/yy')})`}</MenuItem>
                            <MenuItem value="M/d/yy">{`M/d/yy (${dateTimePattern('M/d/yy')})`}</MenuItem>
                        </Select>
                    </StyledFormControl>
                </div>
                <div>
                    <StyledFormControl>
                        <InputLabel>{i18n.tc('account.preference.timePlaceholder')}</InputLabel>
                        <Select
                            value={timeFormat}
                            onChange={handleTimeFormatChange}
                            label={i18n.tc('account.preference.timePlaceholder')}
                        >
                            <MenuItem value="HH:mm">{`HH:mm (${dateTimePattern('HH:mm')})`}</MenuItem>
                            <MenuItem value="h:mm a">{`h:mm a (${dateTimePattern('h:mm a')})`}</MenuItem>
                        </Select>
                    </StyledFormControl>
                </div>
                <ButtonsGroupWithHelp label={i18n.tc('account.create.button')} onBigButtonClick={processCreate} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Preference;
