import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { TextField, Autocomplete } from '@mui/material';

import { useI18n } from '@braincube/i18n';

function getOptionLabel(option) {
    return `${option.site.groupName} ${option.siteName}`;
}

function SiteChooser({ me, selectedProduct, setSelectedProduct }) {
    const location = useLocation();
    const i18n = useI18n();

    const products = useMemo(() => {
        try {
            const uuids = [
                ...new Set(
                    me.accessList
                        .filter((access) => access.product.productId !== location.state?.productId)
                        .filter((access) => access.product.siteName !== 'default')
                        .map((access) => access.product.site.siteUuid)
                ),
            ];

            return uuids.map((uuid) => me.accessList.find((access) => access.product.site.siteUuid === uuid).product);
        } catch (e) {
            return [];
        }
    }, [location, me.accessList]);

    const renderInput = useCallback(
        (params) => <TextField {...params} label={i18n.tc('onboarding.site.chooseExistingSite')} />,
        [i18n]
    );

    const handleChange = useCallback((event, newSite) => setSelectedProduct(newSite), [setSelectedProduct]);

    return (
        <Autocomplete
            renderInput={renderInput}
            getOptionLabel={getOptionLabel}
            value={selectedProduct}
            onChange={handleChange}
            options={products}
        />
    );
}

export const accessPropType = PropTypes.shape({
    product: PropTypes.shape({
        productId: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        site: PropTypes.shape({
            siteUuid: PropTypes.string.isRequired,
            groupName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
});

SiteChooser.propTypes = {
    me: PropTypes.shape({
        accessList: PropTypes.arrayOf(accessPropType).isRequired,
    }),
    selectedProduct: accessPropType,
    setSelectedProduct: PropTypes.func.isRequired,
};

SiteChooser.defaultProps = {
    me: null,
    selectedProduct: null,
};

export default SiteChooser;
