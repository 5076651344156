import React, { useState, useContext, useEffect, useCallback } from 'react';

import { Link, Typography, Alert } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { checkCertificateTicket, downloadCertificate } from 'services/api/sso';
import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';

function Certificate() {
    const i18n = useI18n();
    const { dispatch } = useContext(SsoContext);

    const [certificateToken, setCertificateToken] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        dispatch(startFetching());
        const parameters = new URLSearchParams(window.location.search);
        checkCertificateTicket(parameters.get('code'))
            .then(() => {
                dispatch(stopFetching());
                setCertificateToken(parameters.get('code'));
            })
            .catch(() => {
                dispatch(stopFetching());
                setError(true);
            });
    }, [dispatch]);

    const handleClick = useCallback(() => {
        dispatch(startFetching());

        downloadCertificate(certificateToken)
            .then((res) => res.blob())
            .then((blobby) => {
                const url = window.URL.createObjectURL(blobby);
                const tempLink = document.createElement('a');
                tempLink.href = url;
                tempLink.setAttribute('download', 'certificate.p12');
                tempLink.click();
                dispatch(stopFetching());
            })
            .catch(() => {
                dispatch(stopFetching());
                setError(true);
            });
    }, [certificateToken, dispatch]);
    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('certificate.ticket.title')}</MainSectionTitle>
            <MainSectionContent>
                <div>
                    <Typography variant="body2">{i18n.tc('certificate.ticket.message')}</Typography>
                    <Typography variant="body2">
                        {i18n.t('certificate.ticket.onMacOs')}{' '}
                        <Link underline="always" href="/certificate/how-to-install?browser=chrome&os=macos">
                            {i18n.tc('certificate.ticket.chrome')}
                        </Link>
                        {' / '}
                        <Link underline="always" href="/certificate/how-to-install?browser=firefox&os=macos">
                            {i18n.tc('certificate.ticket.firefox')}
                        </Link>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {i18n.t('certificate.ticket.onWindows')}{' '}
                        <Link underline="always" href="/certificate/how-to-install?browser=chrome&os=windows">
                            {i18n.tc('certificate.ticket.chrome')}
                        </Link>
                        {' / '}
                        <Link underline="always" href="/certificate/how-to-install?browser=firefox&os=windows">
                            {i18n.tc('certificate.ticket.firefox')}
                        </Link>
                        {' / '}
                        <Link underline="always" href="/certificate/how-to-install?browser=edge&os=windows">
                            {i18n.tc('certificate.ticket.edge')}
                        </Link>
                    </Typography>

                    {!error ? (
                        <Alert severity="warning">{i18n.tc('certificate.ticket.onlyOneTime')}</Alert>
                    ) : (
                        <Alert severity="error">{i18n.tc('certificate.ticket.error')}</Alert>
                    )}
                </div>
                <ButtonsGroupWithHelp
                    disableBigButton={error}
                    onBigButtonClick={handleClick}
                    label={i18n.tc('certificate.ticket.download')}
                />
            </MainSectionContent>
        </MainSection>
    );
}

export default Certificate;
