import React from 'react';

import { Typography, styled } from '@mui/material';

const StyledTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 0),
    minHeight: 70,
    boxSizing: 'border-box',
}));

const StyledTypography = styled(Typography)({
    letterSpacing: '0.25em',
});

function ContentTitle({ children }) {
    return (
        <StyledTitle>
            <StyledTypography variant="h6">{children}</StyledTypography>
        </StyledTitle>
    );
}

export default ContentTitle;
