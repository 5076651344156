import React, { useState, useContext, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import InputPassword from 'components/InputPassword';
import Explanation from 'components/Explanation';
import { SsoContext, setEmail, startFetching, stopFetching, checkFavoriteApplication } from 'App/contexts';
import { challenge, validateRgpd } from 'services/api/sso';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import EmailButton from 'components/EmailButton';
import { MainSection, MainSectionTitle, MainSectionContent, MainSectionFooter } from 'components/MainSection';
import Gdpr from 'scenes/Account/Gdpr';
import UserAgreement from 'scenes/Onboarding/UserAgreement';

const GdprComponent = <Gdpr />;

function Challenge() {
    const { state, dispatch } = useContext(SsoContext);
    const i18n = useI18n();
    const navigate = useNavigate();
    const location = useLocation();

    const [password, setPassword] = useState('');
    const [isPasswordValid, setPasswordValid] = useState(true);
    const [responseError, setResponseError] = useState(null);
    const [lastProductUrl, setLastProductUrl] = useState('');
    const [showUserAgreement, setShowUserAgreement] = useState(false);

    const successRedirect = useCallback(
        (message) => {
            const parameters = new URLSearchParams(window.location.search);
            const redirectParameter = parameters.get('redirect');

            if (redirectParameter !== null) {
                window.location.assign(redirectParameter);
            } else if (message !== null) {
                checkFavoriteApplication(message);
            } else {
                dispatch(stopFetching());
                navigate('/no-access');
            }
        },
        [dispatch, navigate]
    );

    const challengeSucceeded = useCallback(
        (response) => {
            if (response.status === 200) {
                response.json().then(({ type, message, payload }) => {
                    if (type === 'PASS_RGPD') {
                        setLastProductUrl(message);
                        setShowUserAgreement(true);
                        dispatch(stopFetching());
                    } else if (type === 'PASS') {
                        successRedirect(message);
                    } else if (type === 'PASS_ONBOARDING') {
                        dispatch(stopFetching());
                        navigate('/onboarding/link');
                    } else if (type === 'CHANGE_PWD') {
                        dispatch(stopFetching());
                        navigate('/password/change');
                    } else if (type === 'OTP_NEEDED') {
                        dispatch(stopFetching());
                        navigate({ pathname: '/challenge/2fa', search: location.search }, { state: { step: type } });
                    } else if (type === 'CHANGE_2FA') {
                        dispatch(stopFetching());
                        navigate(
                            { pathname: '/2fa/change', search: location.search },
                            {
                                state: {
                                    step: type,
                                    seed: JSON.parse(payload),
                                },
                            }
                        );
                    }
                });
            }
        },
        [dispatch, navigate, location.search, successRedirect]
    );

    const challengeFailed = useCallback(
        (response) => {
            dispatch(stopFetching());
            if (response.status === 401) {
                response.json().then(({ type }) => {
                    let formattedType;
                    switch (type) {
                        case 'BAD_PWD':
                            formattedType = 'password.badPassword';
                            break;
                        case 'ACCOUNT_BLOCKED':
                            formattedType = 'lookup.accountBlocked';
                            break;
                        default:
                            formattedType = '';
                    }

                    setPasswordValid(false);
                    setResponseError(formattedType);
                });
            } else {
                navigate('/');
            }
        },
        [dispatch, navigate]
    );

    const processChallenge = useCallback(() => {
        dispatch(startFetching());

        challenge(password).then(challengeSucceeded).catch(challengeFailed);
    }, [challengeFailed, challengeSucceeded, dispatch, password]);

    const checkPassword = useCallback(() => {
        if (password === '') {
            setPasswordValid(false);
        } else {
            setPasswordValid(true);
            processChallenge();
        }
    }, [password, processChallenge]);

    const labels = useMemo(
        () => ({
            title: i18n.tc('onboarding.userAgreement.rgpd.title'),
            indication: i18n.tc('onboarding.userAgreement.rgpd.indication'),
            checkBoxLabel: i18n.tc('onboarding.userAgreement.checkbox'),
            postpone: i18n.tc('onboarding.userAgreement.postpone'),
        }),
        [i18n]
    );

    const handleUserAgreementAccept = useCallback(() => {
        validateRgpd().then(() => successRedirect(lastProductUrl));
        setShowUserAgreement(false);
    }, [lastProductUrl, successRedirect]);

    const handleUserAgreementPostpone = useCallback(() => {
        successRedirect(lastProductUrl);
        setShowUserAgreement(false);
    }, [lastProductUrl, successRedirect]);

    const resetEmail = useCallback(() => {
        dispatch(setEmail(''));
        navigate('/');
    }, [dispatch, navigate]);

    const forgotPassword = useCallback(() => {
        navigate('/recovery');
    }, [navigate]);

    return showUserAgreement ? (
        <UserAgreement
            labels={labels}
            content={GdprComponent}
            onUserAgreementAccept={handleUserAgreementAccept}
            onUserAgreementPostpone={handleUserAgreementPostpone}
        />
    ) : (
        <MainSection onEnter={checkPassword}>
            <MainSectionTitle>{i18n.t('challenge.title')}</MainSectionTitle>
            <MainSectionContent>
                <EmailButton onClick={resetEmail} email={state.email} />
                <Explanation>
                    <InputPassword
                        name="password"
                        placeholder={i18n.tc('app.passwordPlaceholder')}
                        value={password}
                        onChange={setPassword}
                        error={!isPasswordValid}
                        textError={responseError ? i18n.tc(responseError) : responseError}
                        autoFocus
                    />
                </Explanation>
                <ButtonsGroupWithHelp onBigButtonClick={checkPassword} label={i18n.tc('app.nextButton')} />
            </MainSectionContent>
            <MainSectionFooter>
                <span role="button" tabIndex={0} onClick={forgotPassword}>
                    <Typography variant="overline">{i18n.t('challenge.forgotPassword')}</Typography>
                </span>
            </MainSectionFooter>
        </MainSection>
    );
}

export default Challenge;
