import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { SsoContext, setEmail, startFetching, stopFetching } from 'App/contexts';
import { recovery } from 'services/api/sso';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import EmailButton from 'components/EmailButton';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';

function Recovery() {
    const { state, dispatch } = useContext(SsoContext);
    const i18n = useI18n();
    const navigate = useNavigate();

    const resetEmail = useCallback(() => {
        dispatch(setEmail(''));
        navigate('/');
    }, [dispatch, navigate]);

    const handleRecovery = useCallback(() => {
        dispatch(startFetching());

        recovery()
            .then(() => {
                dispatch(stopFetching());

                navigate('/recovery/confirm');
            })
            .catch((response) => {
                dispatch(stopFetching());

                if (response.status === 403) {
                    navigate('/recovery/error');
                }
            });
    }, [dispatch, navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('recovery.title')}</MainSectionTitle>
            <MainSectionContent>
                <EmailButton onClick={resetEmail} email={state.email} />
                <Explanation>
                    <Typography variant="body2">{i18n.tc('recovery.askConfirmation')}</Typography>
                </Explanation>
                <ButtonsGroupWithHelp onBigButtonClick={handleRecovery} label={i18n.tc('recovery.button')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Recovery;
