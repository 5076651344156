import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

/**
 * React component used to set the favicon and appletouch icons on an application.
 * This component allow us to update the HTML <head> section after the page load.
 */
function DynamicFavicon({ appletouch, favicon }) {
    return (
        <Helmet>
            {appletouch && <link rel="apple-touch-icon" href={appletouch} />}
            {favicon && <link rel="icon" href={favicon} />}
        </Helmet>
    );
}

DynamicFavicon.propTypes = {
    favicon: PropTypes.string,
    appletouch: PropTypes.string,
};

DynamicFavicon.defaultProps = {
    favicon: null,
    appletouch: null,
};

export default DynamicFavicon;
