import React from 'react';

import { styled } from '@mui/material';

const StyledFooter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledLink = styled('div')(({ theme }) => ({
    '&> *': {
        display: 'inline-block',
        padding: theme.spacing(1, 0),
        width: '100%',
        textTransform: 'uppercase',
        color: theme.palette.common.white,
        fontSize: 10,
        letterSpacing: 3,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

function ContentFooter({ children }) {
    return (
        <StyledFooter>
            <StyledLink>{children}</StyledLink>
        </StyledFooter>
    );
}

export default ContentFooter;
