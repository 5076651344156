import React, { useContext, useEffect, useState } from 'react';

import { Link, Typography, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { SsoContext } from 'App/contexts';

const StyledTypography = styled(Typography)({
    whiteSpace: 'pre-wrap',
});

function Gdpr() {
    const i18n = useI18n();
    const { state } = useContext(SsoContext);
    const [distantGdpr, setDistantGdpr] = useState(null);

    useEffect(() => {
        fetch(`https://${window.BC_API_ENDPOINTS_CONF.cdn}/gdpr.json`, {
            headers: {
                Accept: 'application/json',
            },
        }).then((response) => {
            if (response.ok) {
                response.json().then((json) => {
                    // todo : use normalized locale in applicaiton
                    if (Object.prototype.hasOwnProperty.call(json, state.lang.replace('en-us', 'en-US'))) {
                        setDistantGdpr({ text: json[state.lang.replace('en-us', 'en-US')], url: json.url });
                    } else if (Object.prototype.hasOwnProperty.call(json, 'en')) {
                        setDistantGdpr({ text: json.en, url: json.url });
                    }
                });
            }
        });
    }, [i18n, state.lang]);

    return distantGdpr !== null && distantGdpr.text !== '' ? (
        <>
            <StyledTypography variant="body2" align="left" color="secondary" gutterBottom>
                {distantGdpr.text}
            </StyledTypography>
            <Link color="secondary" href={distantGdpr.url} target="_blank">
                {distantGdpr.url}
            </Link>
        </>
    ) : (
        <>
            <Typography variant="body2" align="left" color="secondary" gutterBottom>
                {i18n.tc('gdpr.firstPart')}
            </Typography>
            <Typography variant="body2" align="left" color="secondary">
                {i18n.tc('gdpr.middlePart')}
                <Link color="secondary" href="/braincube-end-user-license-agreement.pdf" target="_blank">
                    {i18n.t('gdpr.here')}
                </Link>
                {i18n.tc('gdpr.lastPart')}
            </Typography>
        </>
    );
}

export default Gdpr;
