import React, { useState, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import Explanation from 'components/Explanation';
import { SsoContext, startFetching, stopFetching, setEmail } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import Input from 'components/Input';
import { board, createUser } from 'services/api/sso';

const parameters = new URLSearchParams(window.location.search);

function Onboarding() {
    const { state, dispatch } = useContext(SsoContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [isEmailValid, setEmailValid] = useState(true);
    const [emailValidError, setEmailValidError] = useState('');
    const [boxId, setBoxId] = useState(parameters.get('boxId') || '');
    const [isBoxIdValid, setBoxIdValid] = useState(true);
    const [boxIdError, setBoxIdError] = useState(null);

    const i18n = useI18n();

    const processBoarding = useCallback(() => {
        if (isEmailValid && isBoxIdValid) {
            dispatch(startFetching());

            // Fix [#3384] : in case of auth delegate, after auth, redirect to onboard/link
            const redirectUrl = new URL(`${window.location.origin}${location.pathname}/link`);
            const productIdParameter = parameters.get('productId');
            if (productIdParameter) {
                redirectUrl.searchParams.append('productId', productIdParameter);
            }
            if (boxId) {
                redirectUrl.searchParams.append('boxId', boxId);
            }
            board(boxId, state.email, productIdParameter, redirectUrl.href)
                .then((boardResponse) => {
                    dispatch(stopFetching());

                    if (boardResponse.status === 204) {
                        dispatch(startFetching());

                        createUser(state.email)
                            .then((createUserResponse) => {
                                dispatch(stopFetching());

                                if (createUserResponse.status === 200) {
                                    navigate('/onboarding/confirm');
                                }
                            })
                            .catch(() => {
                                dispatch(stopFetching());
                                setEmailValid(false);
                                setEmailValidError('onboarding.userCreationError');
                            });
                    } else if (boardResponse.status === 200) {
                        boardResponse
                            .json()
                            .then(({ type, message }) => {
                                if (type === 'REDIRECT') {
                                    window.location.assign(`https://${window.BC_API_ENDPOINTS_CONF.sso}${message}`);
                                } else {
                                    navigate('/challenge');
                                }
                            })
                            .catch(() => {
                                navigate('/challenge');
                            });
                    }
                })
                .catch((response) => {
                    dispatch(stopFetching());

                    if (response.status === 403) {
                        setBoxIdError('onboarding.invalidBoxId');
                        setBoxIdValid(false);
                    }
                });
        }
    }, [boxId, dispatch, navigate, location, isBoxIdValid, isEmailValid, state.email]);

    const checkForm = useCallback(() => {
        setEmailValid(
            !(
                state.email === '' ||
                !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    state.email
                )
            )
        );
        setBoxIdValid(boxId !== '');
        setBoxIdError(null);
        setEmailValidError(null);

        processBoarding();
    }, [boxId, processBoarding, state.email]);

    const handleEmailChange = useCallback((email) => dispatch(setEmail(email)), [dispatch]);

    return (
        <MainSection onEnter={checkForm}>
            <MainSectionTitle>{i18n.t('onboarding.activate')}</MainSectionTitle>
            <MainSectionContent>
                <Typography variant="body2">{`1. ${i18n.tc('onboarding.boxIdIndication')}`}</Typography>
                <Explanation>
                    <Input
                        name="boardingBoxId"
                        value={boxId}
                        type="text"
                        placeholder="XXXX-XXXX-XXXX-XXXX-XX"
                        onChange={setBoxId}
                        error={!isBoxIdValid}
                        textError={boxIdError ? i18n.tc(boxIdError) : null}
                        autoFocus
                    />
                </Explanation>
                <Explanation>
                    <Typography variant="body2">{`2. ${i18n.tc('onboarding.emailIndication')}`}</Typography>
                </Explanation>
                <Explanation>
                    <Input
                        name="mail"
                        type="email"
                        placeholder={i18n.tc('app.emailPlaceholder')}
                        onChange={handleEmailChange}
                        value={state.email}
                        error={!isEmailValid}
                        textError={emailValidError ? i18n.tc(emailValidError) : null}
                    />
                </Explanation>
                <ButtonsGroupWithHelp label={i18n.tc('app.nextButton')} onBigButtonClick={checkForm} />
            </MainSectionContent>
        </MainSection>
    );
}

export default Onboarding;
